/**
 * @file
 * A component displaying Trip Expense information
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import styled from '@emotion/native';
import _ from 'lodash';

import app from '@appFirebase';
import { FitToContentButton } from '@appComponents/Button';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import {
  getExpenseTotals,
  groupExpenseAdjustments,
  sumAdjustments,
} from '@appUtils/accounting';
import { useAircraftData } from '@appUtils/aircraft';
import { useCompanyData, getUserData } from '@appUtils/api';
import { useTripExpensesHaveComments } from '@appUtils/expenseComments';
import { formatNumber } from '@appUtils/numbers';
import { useUserData } from '@appUtils/user';
import ExpenseTable, {
  initialSort,
  titlePath,
} from '@webComponents/Accounting/ExpenseTable';
import ExportSection, {
  AdjustmentExportSection,
} from '@webComponents/Accounting/Exports';
import {
  AccountsPayable,
  AccountsReceivable,
} from '@webComponents/Accounting/Adjustments';
import {
  ExpensesApprovedPill,
  ExpensesCompletePill,
} from '@webComponents/Accounting/General';
import { useExpenseDialog } from '@webComponents/Accounting/ExpenseDialog';
import { useStorageImages } from '@webUtils/storage';

const makePaymentBetweenAccounts = app
  .functions()
  .httpsCallable('makePaymentBetweenAccounts');

type TripExpensesProps = {
  trip: Object,
  save: Function,
  update: Function,
  style?: ViewStyle,
};

const getPilotData = async pilotId => {
  const pilot = await getUserData(pilotId);
  return pilot;
};

// TODO: Improve layout for iPad

const TripExpenses = ({ trip, save, update, style }: TripExpensesProps) => {
  const [aircraft] = useAircraftData(trip?.aircraft?.path);
  const [company] = useCompanyData();
  const [imagePaths, setImagePaths] = useState([]);
  const [picData, setPicData] = useState({});
  const [sicData, setSicData] = useState({});
  const [ownerData] = useUserData(trip?.owner?.id);
  const theme = useTheme();
  const tripName = `${trip?.identifier} - ${trip?.customName ?? ''}`;
  const expenses = useMemo(() => trip?.expenses ?? [], [trip?.expenses]);
  const expenseAdjustments = useMemo(
    () => trip?.expenseAdjustments ?? [],
    [trip?.expenseAdjustments],
  );
  const ownerName = trip?.owner
    ? `${trip?.owner?.firstName} ${trip?.owner?.lastName}`
    : '';
  const picName = trip?.pilots[0]
    ? `${trip?.pilots[0]?.firstName} ${trip?.pilots[0]?.lastName}`
    : '';
  const sicName = trip?.pilots[1]
    ? `${trip?.pilots[1]?.firstName} ${trip?.pilots[1]?.lastName}`
    : '';
  const ownerHasStripeAccount =
    (ownerData?.paymentMethodId || ownerData?.stripeCustomerId) ?? false;
  const picHasStripeAccount =
    (picData?.stripeConnectAccountId && picData?.detailsSubmitted) ?? false;
  const sicHasStripeAccount =
    (sicData?.stripeConnectAccountId && sicData?.detailsSubmitted) ?? false;
  const images = useStorageImages(imagePaths);
  const {
    expenseTotal,
    totalDueFromOwner,
    totalDueToPilots,
    totalDueToPic,
    totalDueToSic,
  } = getExpenseTotals(expenses, picName, sicName);
  const { ownerAdjustments, picAdjustments, sicAdjustments } =
    groupExpenseAdjustments(expenseAdjustments, ownerName, picName, sicName);
  const totalOwnerAdjustments = sumAdjustments(ownerAdjustments);
  const totalPicAdjustments = sumAdjustments(picAdjustments);
  const totalSicAdjustments = sumAdjustments(sicAdjustments);
  const picExpensesComplete = trip?.picExpensesComplete ?? false;
  const sicExpensesComplete = trip?.sicExpensesComplete ?? false;
  const expensesComplete = trip?.expensesComplete ?? false;
  const expensesSentToOwner = trip?.expensesSentToOwner ?? false;
  const expensesApproved = trip?.expensesApproved ?? false;
  const tripId = trip?.id;
  const tripExpensesHaveComments = useTripExpensesHaveComments(tripId);
  const [hasExpenseComments, setHasExpenseComments] = useState({});
  const [anyDialogOpen, setAnyDialogOpen] = useState(false);
  useEffect(() => {
    if (!anyDialogOpen) {
      if (!_.isEqual(tripExpensesHaveComments, hasExpenseComments)) {
        setHasExpenseComments(tripExpensesHaveComments);
      }
    }
  }, [anyDialogOpen, hasExpenseComments, tripExpensesHaveComments]);

  useEffect(() => {
    if (trip?.pilots[0]) {
      getPilotData(trip?.pilots[0].id).then(setPicData);
    }
    if (trip?.pilots[1]) {
      getPilotData(trip?.pilots[1].id).then(setSicData);
    }
  }, [trip?.pilots]);

  const [sort, setSort] = useState(initialSort);
  const [sortedExpenses, setSortedExpenses] = useState(expenses);
  const [expandedExpenses, setExpandedExpenses] = useState({});
  const sendToOwner = useCallback(() => {
    update({ expensesSentToOwner: true });
    save();
  }, [save, update]);
  const closeOrReopenExpenses = useCallback(() => {
    const updateValues = expensesComplete
      ? reopenExpenseValues
      : closeExpenseValues;
    update({ ...updateValues });
    save();
  }, [expensesComplete, save, update]);
  const updateSort = useCallback(
    newSort => {
      setSortedExpenses(
        _.orderBy(sortedExpenses, [titlePath[newSort.title]], [newSort.dir]),
      );
      setSort(newSort);
    },
    [sortedExpenses],
  );
  const { dialogNode, openDialog } = useExpenseDialog({
    mode: 'Add',
    saveTrip: save,
    updateTrip: update,
    currentExpenses: expenses,
    expenseCategories: company?.config?.expenseTypes ?? [],
    paymentMethods: aircraft?.paymentMethods ?? [],
    tripId: tripId,
    fuelUnits: aircraft?.fuelUnits ?? 'gal',
    setAnyDialogOpen,
  });

  useEffect(() => {
    const newSort = _.orderBy(expenses, [titlePath[sort.title]], [sort.dir]);
    if (!_.isEqual(newSort, sortedExpenses)) {
      setSortedExpenses(newSort);
    }
  }, [sort.dir, sort.title, expenses, sortedExpenses]);

  useEffect(() => {
    const paths = expenses.map(expense =>
      expense.photoUrls ? expense.photoUrls : [],
    );
    const flattenedPaths = _.flatten(paths);
    if (!_.isEqual(flattenedPaths, imagePaths)) {
      setImagePaths(flattenedPaths);
    }
  }, [expenses, imagePaths]);

  const processPaymentPic = async (remainingAmount, feeAmount, paymentType) => {
    const data = {
      amount: remainingAmount,
      feeAmount: feeAmount,
      paymentType: paymentType,
      tripId: tripId,
      tripIdentifier: trip?.identifier,
      managementCompanyId: company.id,
      destinationUserId: trip?.pilots[0].id,
    };
    const checkoutData = await makePaymentBetweenAccounts(data);
    window.location.href = checkoutData.data.checkoutUrl;
  };

  const processPaymentSic = async (remainingAmount, feeAmount, paymentType) => {
    const data = {
      amount: remainingAmount,
      feeAmount: feeAmount,
      paymentType: paymentType,
      tripId: tripId,
      tripIdentifier: trip?.identifier,
      managementCompanyId: company.id,
      destinationUserId: trip?.pilots[1].id,
    };
    const checkoutData = await makePaymentBetweenAccounts(data);
    window.location.href = checkoutData.data.checkoutUrl;
  };

  return (
    <Box pv={1} style={style} ph={2}>
      <Spacer size={1.5} />
      {dialogNode}
      <ExpenseTotals
        currentDueFromOwner={totalDueFromOwner + totalOwnerAdjustments}
        currentDueToPilots={
          totalDueToPilots + totalPicAdjustments + totalSicAdjustments
        }
        totalTripExpense={expenseTotal}
      />
      <ExpensesTopBar
        expensesComplete={expensesComplete}
        picExpensesComplete={picExpensesComplete}
        sicExpensesComplete={sicExpensesComplete}
        expensesApproved={expensesApproved}
        ownerHasStripeAccount={ownerHasStripeAccount}
      />
      <Spacer />
      <SendAndExportRow
        data={expenses}
        tripName={tripName}
        expensesComplete={expensesComplete}
        closeOrReopenExpenses={closeOrReopenExpenses}
        images={images}
        fuelUnits={aircraft?.fuelUnits ?? 'gal'}
        openDialog={openDialog}
        theme={theme}
      />
      <ExpenseTable
        unsortedExpenses={expenses}
        sortedExpenses={sortedExpenses}
        updateSort={updateSort}
        expandedExpenses={expandedExpenses}
        setExpandedExpenses={setExpandedExpenses}
        images={images}
        updateTrip={update}
        saveTrip={save}
        company={company}
        aircraft={aircraft}
        tripId={tripId}
        tripExpenseComments={hasExpenseComments}
        setAnyDialogOpen={setAnyDialogOpen}
      />
      <Spacer size={1} />
      <Row jc="flex-end">
        <AdjustmentExportSection trip={trip} tripName={tripName} />
      </Row>
      <Spacer size={1} />
      <Row>
        <AccountsPayable
          theme={theme}
          pilots={trip?.pilots}
          picName={picName}
          sicName={sicName}
          totalDueToPilots={totalDueToPilots}
          totalDueToPic={totalDueToPic}
          totalDueToSic={totalDueToSic}
          existingAdjustments={expenseAdjustments}
          picAdjustments={picAdjustments}
          totalPicAdjustments={totalPicAdjustments}
          sicAdjustments={sicAdjustments}
          totalSicAdjustments={totalSicAdjustments}
          picExpensesComplete={picExpensesComplete}
          sicExpensesComplete={sicExpensesComplete}
          update={update}
          save={save}
          processPaymentPic={processPaymentPic}
          processPaymentSic={processPaymentSic}
          picHasStripeAccount={picHasStripeAccount}
          sicHasStripeAccount={sicHasStripeAccount}
          expensesApproved={expensesApproved}
        />
        <AccountsReceivable
          theme={theme}
          owner={trip?.owner}
          ownerName={ownerName}
          totalDueFromOwner={totalDueFromOwner}
          existingAdjustments={expenseAdjustments}
          ownerAdjustments={ownerAdjustments}
          totalOwnerAdjustments={totalOwnerAdjustments}
          update={update}
          save={save}
          showSendToOwner={expensesComplete && !expensesSentToOwner}
          sendToOwner={sendToOwner}
          ownerHasStripeAccount={ownerHasStripeAccount}
        />
      </Row>
    </Box>
  );
};

const closeExpenseValues = {
  expensesComplete: true,
};

const reopenExpenseValues = {
  expensesComplete: false,
  picExpensesComplete: false,
  sicExpensesComplete: false,
  expensesSentToOwner: false,
  expensesApproved: false,
};

const ExpensesTopBarWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  height: '54px',
  alignItems: 'center',
  justifyContent: 'left',
  paddingLeft: '15px',
  backgroundColor: theme.colors.background,
  borderRadius: theme.roundness,
}));

const ExpensesTopBar = ({
  expensesComplete,
  picExpensesComplete,
  sicExpensesComplete,
  expensesApproved,
  ownerHasStripeAccount,
}) =>
  expensesComplete ||
  picExpensesComplete ||
  sicExpensesComplete ||
  expensesApproved ? (
    <ExpensesTopBarWrapper>
      <Row jc="left" w={'90%'}>
        <ExpensesCompletePill
          expensesComplete={expensesComplete}
          picExpensesComplete={picExpensesComplete}
          sicExpensesComplete={sicExpensesComplete}
        />
        <ExpensesApprovedPill
          expensesApproved={expensesApproved}
          ownerHasStripeAccount={ownerHasStripeAccount}
        />
      </Row>
    </ExpensesTopBarWrapper>
  ) : null;

const ExpenseTotals = ({
  currentDueFromOwner,
  currentDueToPilots,
  totalTripExpense,
}) => (
  <Box dir="row" jc="space-between" mb={1}>
    <TotalBox>
      <Text size="medium">Total Trip Expense</Text>
      <Spacer size={0.5} />
      <Text color="#5AFFC7" size="large">
        $
        {formatNumber({
          value: totalTripExpense.toFixed(2),
          decimalScale: 2,
        })}
      </Text>
    </TotalBox>
    <TotalBox>
      <Text size="medium">Current Due from Client</Text>
      <Spacer size={0.5} />
      <Text color="#5AFFC7" size="large">
        ${formatNumber({ value: currentDueFromOwner.toFixed(2) })}
      </Text>
    </TotalBox>
    <TotalBox>
      <Text size="medium">Current Due to Pilots</Text>
      <Spacer size={0.5} />
      <Text color="#5AFFC7" size="large">
        ${formatNumber({ value: currentDueToPilots.toFixed(2) })}
      </Text>
    </TotalBox>
    <TotalBox>
      <Text size="medium">Current Net (Client-Pilots=Net)</Text>
      <Spacer size={0.5} />
      <Text color="#5AFFC7" size="large">
        $
        {formatNumber({
          value: (
            currentDueFromOwner.toFixed(2) - currentDueToPilots.toFixed(2)
          ).toFixed(2),
          decimalScale: 2,
        })}
      </Text>
    </TotalBox>
  </Box>
);

const TotalBox = styled(Box)(({}) => ({
  borderRadius: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.06)',
  width: '24%',
  padding: 15,
}));

const Row = styled(View)(({ h, c, mt, ph, jc, w, theme }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  justifyContent: jc ?? 'space-between',
  alignItems: 'center',
  height: h ?? undefined,
  backgroundColor: c ?? undefined,
  marginTop: mt ?? undefined,
  paddingHorizontal: ph ?? undefined,
  borderRadius: theme.roundness,
}));

const CloseOrReopenButton = ({ expensesComplete, closeOrReopenExpenses }) => (
  <FitToContentButton onPress={closeOrReopenExpenses}>
    {expensesComplete ? 'REOPEN EXPENSES' : 'CLOSE EXPENSES'}
  </FitToContentButton>
);

const SendAndExportRow = ({
  data,
  tripName,
  expensesComplete,
  closeOrReopenExpenses,
  images,
  fuelUnits,
  openDialog,
  theme,
}) => (
  <Row>
    <CloseOrReopenButton
      expensesComplete={expensesComplete}
      closeOrReopenExpenses={closeOrReopenExpenses}
    />
    <FitToContentButton onPress={openDialog} ml={theme.layout.space(0.5)}>
      + ADD EXPENSE
    </FitToContentButton>
    <ExportSection
      data={data}
      tripName={tripName}
      images={images}
      fuelUnits={fuelUnits}
    />
  </Row>
);

export default TripExpenses;

import { useEffect } from 'react';

export const useFlightTimesCalculations = ({
  watchFlightTimes,
  isHobbs,
  isAPUHobbs,
  setValue,
  trip,
  aircraft,
}) => {
  useEffect(() => {
    if (!watchFlightTimes || !trip || !aircraft) {
      return;
    }

    const safeParseFloat = value => {
      if (
        value === '-' ||
        value === null ||
        value === undefined ||
        value === ''
      ) {
        return null;
      }
      const parsed = parseFloat(value);
      return isNaN(parsed) ? null : parsed;
    };

    const calculateValue = (
      beginning,
      endingOrCurrent,
      isCurrentMeteringSystem,
      fieldType,
    ) => {
      const beginningValue = safeParseFloat(beginning);
      const endingOrCurrentValue = safeParseFloat(endingOrCurrent);

      if (beginningValue === null && endingOrCurrentValue === null) {
        return undefined;
      }

      const safeBeginning = beginningValue === null ? 0 : beginningValue;
      const safeEndingOrCurrent =
        endingOrCurrentValue === null ? 0 : endingOrCurrentValue;

      let result;
      if (isCurrentMeteringSystem) {
        result = safeEndingOrCurrent - safeBeginning;
      } else {
        result = safeBeginning + safeEndingOrCurrent;
      }

      if (result <= 0) {
        return undefined;
      }

      switch (fieldType) {
        case 'integer':
          return Math.round(result).toString();
        case 'decimal':
          return result.toFixed(1);
        default:
          return result.toString();
      }
    };

    const getFieldType = field => {
      if (field.includes('Cycles') || field.includes('landing')) {
        return 'integer';
      }
      return 'decimal';
    };

    Object.keys(watchFlightTimes).forEach(field => {
      const isAPUField = field.startsWith('apu');
      const isAPUCyclesField = field.startsWith('apuCycle');
      const currentMeteringSystem = isAPUField ? isAPUHobbs : isHobbs;

      if (
        field.endsWith('Beginning') ||
        (currentMeteringSystem && field.endsWith('Ending')) ||
        (!currentMeteringSystem && field.endsWith('Current'))
      ) {
        const baseField = field.replace(/(?:Beginning|Ending|Current)$/, '');
        const beginningValue = watchFlightTimes[`${baseField}Beginning`];
        const endingOrCurrentValue = currentMeteringSystem
          ? watchFlightTimes[`${baseField}Ending`]
          : watchFlightTimes[`${baseField}Current`];

        const fieldType = isAPUCyclesField ? 'integer' : getFieldType(field);

        const calculatedValue = calculateValue(
          beginningValue,
          endingOrCurrentValue,
          currentMeteringSystem,
          fieldType,
        );

        const targetField = currentMeteringSystem
          ? `${baseField}Current`
          : `${baseField}Ending`;

        if (calculatedValue !== watchFlightTimes[targetField]) {
          setValue(`flightTimes.${targetField}`, calculatedValue, {
            shouldValidate: true,
          });
        }
      }
    });
  }, [watchFlightTimes, isHobbs, isAPUHobbs, setValue, trip, aircraft]);
};

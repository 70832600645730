import React, { createContext, useCallback, useContext, useState } from 'react';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import {
  saveMeteringData as saveMeteringDataAPI,
  saveMeteringDataMode as saveMeteringDataModeAPI,
} from '@appUtils/trip';

const MeteringContext = createContext();

export const MeteringProvider = ({ children }) => {
  const [tripPath, setTripPath] = useState('');
  const [selectedLeg, setSelectedLeg] = useState({
    label: '',
    id: -1,
    index: -1,
    value: -1,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm();

  const { isDirty } = useFormState({
    control: methods.control,
  });

  const removeUndefinedValues = useCallback(obj => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          const cleanedValue = removeUndefinedValues(value);
          if (Object.keys(cleanedValue).length > 0) {
            acc[key] = cleanedValue;
          }
        } else {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  }, []);

  const saveMeteringData = useCallback(
    async (isPrefill = false) => {
      setIsSaving(true);
      const updateMode = selectedLeg?.index === -1 ? 'trip' : 'leg';
      const formData = methods.getValues();
      const meteringData = removeUndefinedValues(formData);

      try {
        await saveMeteringDataAPI(
          meteringData,
          tripPath,
          selectedLeg.index,
          updateMode,
        );
        if (isPrefill) {
          if (selectedLeg.index === -1) {
            await saveMeteringDataAPI(meteringData, tripPath, 0, updateMode);
          }
          if (selectedLeg.index === 0) {
            await saveMeteringDataAPI(meteringData, tripPath, -1, updateMode);
          }
        }
      } catch (error) {
        console.error('@saveMeteringData', error);
      } finally {
        setIsSaving(false);
      }
    },
    [methods, removeUndefinedValues, selectedLeg?.index, tripPath],
  );

  const saveMeteringDataMode = useCallback(
    updateMode => saveMeteringDataModeAPI(tripPath, updateMode),
    [tripPath],
  );

  const value = {
    saveMeteringData,
    isDirty,
    isSaving,
    ...methods,
    isLoading,
    setIsLoading,
    setTripPath,
    selectedLeg,
    setSelectedLeg,
    saveMeteringDataMode,
  };

  return (
    <MeteringContext.Provider value={value}>
      <FormProvider {...methods}>{children}</FormProvider>
    </MeteringContext.Provider>
  );
};

export const useMeteringContext = () => {
  const context = useContext(MeteringContext);
  if (!context) {
    throw new Error(
      'useMeteringContext must be used within a MeteringProvider',
    );
  }
  return context;
};

import React from 'react';
import { useController } from 'react-hook-form';
import Radio from '@appComponents/Radio';
import { Box } from '@appComponents/ScreenLayout';

export const RadioButtonField = ({
  name,
  control,
  options,
  defaultValue,
  onChangeInterceptor,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <Box dir="row" jc="space-between">
      {options.map(option => (
        <Radio
          key={option.label}
          label={option.label}
          value={option.value}
          checked={value === option.value}
          onChange={() => {
            onChange(option.value);
            onChangeInterceptor?.(option.value);
          }}
        />
      ))}
    </Box>
  );
};

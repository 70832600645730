/**
 * @format
 * @flow strict-local
 */

import React, { Node, useState } from 'react';
import styled from '@emotion/native';
import { useForm, useFormState } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';

import AuthLayout from './AuthLayout';
import Text from '@appComponents/Text';
import TextLink from '@mobileComponents/TextLink';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import Icon from '@appComponents/theme/Icon';
import {
  useCreateAccountCallback,
  useCreateAccountAndCompanyCallback,
} from '@appUtils/auth';
import { UserRole } from '@appUtils/tripConverter';
import {
  CodeField,
  EmailFormField,
  FirstNameFormField,
  LastNameFormField,
  PasswordFormField,
  PhoneNumberFormField,
  CompanyNameFormField,
  TrialCodeFormField,
} from '@appComponents/forms/FormFields';
import Notification from '@appComponents/Notification';
import Button from '@appComponents/Button';
import { useTheme } from '@appComponents/theme';
import app from '@appFirebase';
import { AircraftQuantitySelectSignup } from '../../components/forms/AircraftQuantitySelectSignup';
import { JoinCompanyButton } from './AuthLayout';
import Checkbox from '@appComponents/Checkbox';

const AccountPane = ({ title, infoText, isCompany = false }): Node => {
  const theme = useTheme();
  return (
    <AuthLayout>
      {!isCompany && (
        <>
          <Spacer size={2} />
          <Icon name="join-company" color={theme.colors.background} size="90" />
        </>
      )}
      <TitleWrap>
        <Text color="dark" size="extraExtraLarge">
          {title}
        </Text>
        <Spacer />
        <Text size="medium">{infoText}</Text>
      </TitleWrap>
      <CreateAccountForm isCompany={isCompany} />
    </AuthLayout>
  );
};

export const CreateAccount = () => (
  <AccountPane
    title="Join an existing management company"
    infoText="Create your own personal account using the authorized company ID"
    buttons={[]}
    join="true"
    FormComponent={CreateAccountForm}
  />
);

export const CreateCompany = () => (
  <AccountPane
    title="Create a company account"
    infoText="Create an account for you and your management company."
    isCompany
  />
);

const TitleWrap = styled.View(({ theme }) => ({
  marginTop: theme.layout.space(3),
  marginBottom: theme.layout.space(3),
  width: '100%',
}));

const validateTrialCodeFunction = app
  .functions()
  .httpsCallable('verifyTrialCode');

const aircraftQuantityOptions = Array.from({ length: 20 }, (_, i) => ({
  name: (i + 1).toString(),
  value: i + 1,
}));

export const CreateAccountForm = ({ isCompany = false }) => {
  const form = useForm({
    defaultValues: {
      role: UserRole.MANAGER,
    },
  });

  return <ProfileInfo form={form} isCompany={isCompany} />;
};

const ProfileInfo = ({ form, isCompany = false }) => {
  const theme = useTheme();
  const { clearErrors, control, watch, setError } = form;
  const { isSubmitting, errors } = useFormState({
    control,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const createAccountAndCompanyCallback = useCreateAccountAndCompanyCallback(
    form,
    setIsSubmitted,
  );

  const companyCode = watch('signUpCode');
  const showProfile = isCompany ? isCompany : companyCode?.length === 5;
  const trialCode = watch('trialCode');

  const handleCreateAccountAndCompanySubmit = async () => {
    const trialCodeId = trialCode;
    if (trialCodeId) {
      const trailCodeReturn = await validateTrialCodeFunction({
        trialCode: trialCodeId,
      });
      if (trailCodeReturn.data.isValid) {
        await createAccountAndCompanyCallback();
        return;
      }
      setError('trialCode', {
        message: trailCodeReturn.data.message || 'Invalid trial code',
      });
      setIsSubmitted(false);
      return;
    }
    await createAccountAndCompanyCallback();
  };

  const createAccountCallback = useCreateAccountCallback(form, setIsSubmitted);
  const handleCreateAccountSubmit = async () => {
    form.clearErrors();
    await createAccountCallback();
  };

  const submit = isCompany
    ? handleCreateAccountAndCompanySubmit
    : handleCreateAccountSubmit;
  const { navigate } = useNavigation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const error = errors.general;
  const aircraftQuantity = watch('aircraftQuantity');

  return (
    <Box>
      {isCompany ? (
        <Box>
          <HeaderText>Enter your company name *</HeaderText>
          <Spacer size={0.25} />
          <CompanyNameFormField control={control} showLabel={false} />
        </Box>
      ) : (
        <Box>
          <HeaderText>Enter your company ID *</HeaderText>
          <Spacer size={0.25} />
          <CodeField control={control} showLabel={false} />
        </Box>
      )}
      {!showProfile && (
        <Text size="medium">
          Contact your aircraft management company to get your company's ID. For
          more support contact us at{' '}
          <TextLink
            label="takeoff@flightapp.io"
            href="mailto:takeoff@flightapp.io"
            paint="true"
          />
          .
        </Text>
      )}
      {showProfile && (
        <>
          <Box dir="row" jc="space-between">
            <Box width="48%">
              <HeaderText>Enter your first name *</HeaderText>
              <Spacer size={0.25} />
              <FirstNameFormField control={control} showLabel={false} />
            </Box>
            <Box width="48%">
              <HeaderText>Enter your last name *</HeaderText>
              <Spacer size={0.25} />
              <LastNameFormField control={control} showLabel={false} />
            </Box>
          </Box>
          <Box>
            <HeaderText>Enter your email address *</HeaderText>
            <Spacer size={0.25} />
            <EmailFormField control={control} showLabel={false} />
          </Box>
          <Box>
            <HeaderText>Enter your phone number *</HeaderText>
            <Spacer size={0.25} />
            <PhoneNumberFormField control={control} showLabel={false} />
          </Box>
          <Box>
            <HeaderText>Enter your password *</HeaderText>
            <Spacer size={0.25} />
            <PasswordFormField control={control} showLabel={false} />
          </Box>
        </>
      )}

      {isCompany && (
        <Box>
          <HeaderText>Enter your free trial code</HeaderText>
          <Spacer size={0.25} />
          <TrialCodeFormField
            control={control}
            hasLabel={true}
            showLabel={false}
          />
        </Box>
      )}
      {isCompany && (
        <Box>
          {/* <HeaderText>Enter the number of aircraft your company manages</HeaderText> */}
          <Spacer size={0.25} />
          <AircraftQuantitySelectSignup
            control={control}
            label="How many aircraft do you manage? *"
            name="aircraftQuantity"
            options={aircraftQuantityOptions}
            theme={theme}
          />
        </Box>
      )}
      {showProfile && (
        <>
          <Box ml={-0.5} dir="row" ai="center">
            <Checkbox
              label
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <Text>Accept all </Text>
            <LinkText onPress={() => navigate('Terms')}>
              Terms & Conditions
            </LinkText>
            <Text> and </Text>
            <LinkText onPress={() => navigate('Privacy')}>
              Privacy Policy
            </LinkText>
          </Box>
          <Spacer size={2} />
          {isCompany && aircraftQuantity && (
            <>
              <Text>
                {trialCode &&
                  'After your trial you will need to subscribe to continue using FlightApp!\n'}
                You will be charged $
                {aircraftQuantity ? aircraftQuantity.value * 50 : 0}/month, you
                can update the number of aircraft you manage in the Settings
                page.
              </Text>
              <Spacer />
            </>
          )}
          <SubmitButton
            loading={isSubmitting || isSubmitted}
            onPress={submit}
            disabled={!termsAccepted || isSubmitting || isSubmitted}>
            {isCompany ? (
              <Text color="background">CREATE A COMPANY ACCOUNT</Text>
            ) : (
              <Text color="background">CREATE ACCOUNT</Text>
            )}
          </SubmitButton>
        </>
      )}
      {isCompany && (
        <>
          <Spacer size={2} />
          <Divider />
          <StyledText>Already have a management company account?</StyledText>
          <Spacer />
          <JoinCompanyButton
            onPress={() => navigate('Join Company')}
            width="100%"
          />
        </>
      )}
      {!showProfile && (
        <>
          <Spacer size={2} />
          <Divider />
          <StyledText>Don't have a management company account?</StyledText>
          <Spacer />
          <Button mode="outlined" onPress={() => navigate('Create Company')}>
            <Text color="primary">CREATE ACCOUNT</Text>
          </Button>
        </>
      )}
      <Spacer />
      <Box dir="row" ai="center" ml={-1.25}>
        <Button mode="text" onPress={() => navigate('Login')}>
          <Box dir="row" ai="center" jc="flex-start">
            <Icon name="back-arrow" size={20} />
            <Spacer dir="horizontal" size={0.5} />
            <ButtonText color="#3E8EF7" fontSize="20" marginLeft="5">
              Back to Login
            </ButtonText>
          </Box>
        </Button>
      </Box>
      <Notification
        color="error"
        visible={Boolean(error)}
        onDismiss={clearErrors}>
        {error?.message}
      </Notification>
      <Spacer size={3} />
    </Box>
  );
};

const SubmitButton = styled(Button)(({ theme, disabled }) => ({
  padding: theme.layout.space(0.25),
  backgroundColor: disabled
    ? theme.colors.disabled
    : theme.colors.mobilePrimary,
}));

const HeaderText = ({ children }) => <Text size="medium">{children}</Text>;

const StyledText = styled(Text)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}));

const ButtonText = styled(Text)(({ color, fontSize, marginLeft }) => ({
  color: color,
  fontSize: fontSize,
  fontWeight: '400',
  marginLeft: marginLeft || '0',
}));

const LinkText = styled(StyledText)(({ theme }) => ({
  color: '#3E8EF7',
  textDecoration: 'underline',
}));

const Divider = styled.View(({}) => ({
  height: '1px',
  backgroundColor: 'gray',
  marginVertical: '20px',
}));

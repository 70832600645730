/**
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { TextInput } from 'react-native-paper';
import styled from '@emotion/native';
import _ from 'lodash';

import { Box, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import Button, { FitToContentButton } from '@appComponents/Button';
import { NumberField } from '@appComponents/forms/FormFields';
import { useTheme } from '@appComponents/theme';

const AddBaggageComponent = ({
  baggage = [],
  update,
  remove,
  append,
  control,
  canUpdate,
  isDirty,
  reset,
  getValues,
  setShouldUpdate,
}) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const WeightStyle = {
    textAlign: 'right',
    height: theme.layout.space(2),
  };

  return (
    <Box>
      <Box dir="row" ai="center">
        {(canUpdate || baggage.length > 0) && (
          <FitToContentButton
            mode="text"
            icon={expanded ? 'minus' : 'plus'}
            onPress={() => setExpanded(!expanded)}
          />
        )}
        <Text size="medium">
          {baggage.length} Bag{baggage.length === 1 ? '' : 's'}
        </Text>
      </Box>
      {expanded && (
        <Box width="80%">
          {baggage.length > 0 && (
            <LeftBorderedBox>
              {baggage?.map((b, index) => (
                <Box
                  dir="row"
                  ai="center"
                  jc="space-evenly"
                  ph={1}
                  key={b.id}
                  height={theme.layout.space(3)}>
                  <Text align="left" size="mediumLarge">
                    BAG {index + 1}:{' '}
                  </Text>
                  <Box width="50%" mb={canUpdate ? -0.75 : 0}>
                    {canUpdate ? (
                      <NumberField
                        labelCol={false}
                        placeholder="Bag Weight"
                        control={control}
                        name={`baggage[${index}].weight`}
                        decimalScale={0}
                        right={<TextInput.Affix text="lbs" />}
                        style={WeightStyle}
                      />
                    ) : (
                      <Text size="mediumLarge">
                        {_.isEmpty(b?.weight) ? 0 : b.weight} lbs
                      </Text>
                    )}
                  </Box>
                  {canUpdate && (
                    <Button
                      icon="close"
                      size={24}
                      color={theme.colors.text}
                      onPress={() => {
                        remove(index);
                      }}
                    />
                  )}
                </Box>
              ))}
            </LeftBorderedBox>
          )}
          {canUpdate && (
            <>
              <Spacer />
              <Box dir="row">
                <FitToContentButton
                  mode="outlined"
                  color="white"
                  onPress={() => append({ weight: '' })}>
                  <Text size="smallest">+ ADD BAG</Text>
                </FitToContentButton>
                {isDirty && (
                  <FitToContentButton
                    mode="outlined"
                    color="white"
                    onPress={() => {
                      const updatedBaggage = getValues('baggage');
                      updatedBaggage?.map((b, i) => {
                        const noCommaWeight = _.filter(
                          b.weight,
                          char => char !== ',',
                        ).join('');
                        update(i, {
                          weight: noCommaWeight,
                        });
                      });
                      setShouldUpdate(true);
                      reset(getValues());
                    }}
                    ml={5}>
                    <Text size="smallest">SAVE BAGGAGE</Text>
                  </FitToContentButton>
                )}
              </Box>
            </>
          )}
          <Spacer />
        </Box>
      )}
    </Box>
  );
};

const LeftBorderedBox = styled(Box)(({ theme }) => ({
  borderWidth: 1,
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
  borderLeftColor: theme.colors.text,
}));

export default AddBaggageComponent;

/**
 * @file
 * Functions to support exporting information related to a trip
 *
 * @format
 * @flow strict-local
 */
import { DateTime } from 'luxon';

export const getFileName = (tripName, extension, desc = 'Expenses') => {
  return `${tripName} ${fileTimestamp()}${desc ? ` ${desc}` : ''}.${extension}`;
};

export const fileTimestamp = () => {
  return DateTime.now().toFormat('y-LL-dd HHmm');
};

const arrayLineToCSV = row => {
  let out = '';
  for (let i = 0; i < row.length; i++) {
    let outRow = '';
    if (row[i]) {
      outRow += `"${row[i]}"`;
    } else {
      outRow += '""';
    }
    if (i < row.length - 1) {
      outRow += ',';
    }
    out += outRow;
  }
  return out + '\n';
};

export const arrayToCSV = contentArray => {
  let outString = '';
  for (let line of contentArray) {
    outString += arrayLineToCSV(line);
  }
  return outString;
};

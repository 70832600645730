/**
 * @file
 * User related hooks and APIs
 *
 * @format
 * @flow strict-local
 */
import _ from 'lodash';

import { useCallback, useMemo } from 'react';
import uuid from 'react-native-uuid';
import app, { FieldPath, useCollection, useDocumentData } from '@appFirebase';
import { useCompanyData, getUserDoc } from '@appUtils/api';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import { UserRole } from '@appUtils/tripConverter';

export const useUserData = id => {
  const ref = app.firestore().collection('users').doc(id);
  const [data, loading, error, snapshot] = useDocumentData(ref);

  const result = {
    ...data,
    id,
  };

  return [result, loading, error, snapshot];
};

export const useTripUserDocuments = userIds => {
  // set a dummy ids value so the query can run while user IDs are still loading
  let ids = ['1'];
  if (!_.isEmpty(userIds) && JSON.stringify(userIds) !== '[null]') {
    ids = userIds;
  }

  // WARNING: use of "in" is limited to 10; if there are more than 10 users on the trip
  // this will be a problem
  const query = app
    .firestore()
    .collection('users')
    .where(FieldPath.documentId(), 'in', ids);

  const [snapshot, , snapError] = useCollection(query);

  const data = useMemo(
    () =>
      _.map(snapshot?.docs, doc => {
        const path = doc.ref.path;
        const docData = doc.data();
        const name = `${docData?.firstName} ${docData?.lastName}`;

        return { ...docData, path, name, id: doc.id };
      }),
    [snapshot?.docs],
  );

  return {
    loading: !snapshot && !snapError,
    error: snapError,
    data,
    snapshot,
  };
};

export const useOwnerPassengers = (ownerId, archived = false) => {
  const [company] = useCompanyData();
  const query = app
    .firestore()
    .collection('users')
    .where('managementCompany.docId', '==', company?.id ?? '1')
    .where('ownerIds', 'array-contains', ownerId);

  const [snapshot, , snapError] = useCollection(query);

  let data = useMemo(
    () =>
      _.chain(snapshot?.docs)
        .map(doc => {
          const path = doc.ref.path;
          const docData = doc.data();
          const name = `${docData?.firstName} ${docData?.lastName}`;
          return { ...docData, path, name, id: doc.id };
        })
        .filter(
          user =>
            // matches the archived value or if they don't have one we'll assume not archived
            _.isUndefined(user?.archived) || user.archived === archived,
        )
        .value(),
    [archived, snapshot?.docs],
  );

  return {
    loading: !snapshot && !snapError,
    error: snapError,
    data,
    snapshot,
  };
};

export const saveUserOwnerIds = (id, ownerIds) => {
  const ref = app.firestore().collection('users').doc(id);
  return ref.update({ ownerIds, dateUpdated: new Date() });
};

export const saveOwnerPassengerIds = ({ ownerId, passengerIds }) => {
  const ref = app.firestore().collection('users').doc(ownerId);
  return ref.update({ passengerIds, dateUpdated: new Date() });
};

export const saveUserDocuments = (id, documents) => {
  const ref = app.firestore().collection('users').doc(id);
  return ref.update('documents', documents);
};

export const useAddPassenger = ({ ownerId }) => {
  const [user, loading] = useUserData(ownerId);

  const addPassenger = useCallback(
    passenger => {
      if (!loading) {
        const passengerSet = new Set();
        user?.passengers?.map(p => passengerSet.add(p));
        passengerSet.add({ name: passenger.name, key: uuid.v4() });

        return app
          .firestore()
          .collection('users')
          .doc(ownerId)
          .update('passengers', Array.from(passengerSet));
      }
    },
    [loading, ownerId, user?.passengers],
  );

  return addPassenger;
};

export const usePassengers = ownerId => {
  const [user, loading] = useUserData(ownerId);
  const passengers = _.get(user, 'passengers', []);

  const updatePassenger = useCallback(
    (updates, passengerIndex) => {
      const updatedPassengers = passengers.map((originalData, index) => {
        if (passengerIndex === index) {
          return {
            ...originalData,
            ...updates,
          };
        }

        return originalData;
      });

      return app
        .firestore()
        .collection('users')
        .doc(ownerId)
        .update('passengers', updatedPassengers);
    },
    [ownerId, passengers],
  );

  return { passengers, updatePassenger, loading };
};

export const getTripUserIds = trip => {
  const tripUserIds = new Set();
  trip?._pilotIds?.map(id => tripUserIds.add(id));
  trip?._passengerIds?.map(id => tripUserIds.add(id));
  return Array.from(tripUserIds);
};

export const updateOtherUser = async ({
  uid,
  firstName,
  lastName,
  phoneNumber,
  weight,
}) => {
  const userRef = getUserDoc(uid);
  const phoneNum = parsePhoneNumberFromString(phoneNumber, 'US').number;

  await userRef.update({
    firstName,
    lastName,
    phoneNumber: phoneNum,
    weight,
    dateUpdated: new Date(),
  });
};

export const getRoleString = role => {
  switch (role) {
    case UserRole.OWNER:
      return 'Client';
    case UserRole.PILOT:
      return 'Pilot';
    case UserRole.PASSENGER:
      return 'Passenger';
    case UserRole.MANAGER:
      return 'Manager';
    case UserRole.EMPLOYEE:
      return 'Employee';
    default:
      return '';
  }
};

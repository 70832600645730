import React, { useState, useEffect } from 'react';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import { TabsContainer } from './TabsContainer';
import { TrendMonitoring } from './TrendMonitoring';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { VorRvsm } from './VorRvsm';
import { FlightTimes } from './FlightTimes';
import { useDefaultValues } from '@appUtils/hooks/metering/useDefaultValues';
import Text from '@appComponents/Text';
import { Icon } from '@appComponents/theme';
import exportMeteringFiles from './ExportMetering';
import { useAircraftData } from '@appUtils/aircraft';
import { isEmpty } from 'lodash';

export const MeteringTab = ({ trip, ...props }) => {
  const [activeTab, setActiveTab] = useState({
    key: 'flightTimes',
    title: 'Flight Times',
  });
  const { setTripPath } = useMeteringContext();
  const { handleUpdateModeChange, handleSelectedLegChange } =
    useDefaultValues(trip);
  const [aircraft, aircraftLoading] = useAircraftData(trip?.aircraft?.path);
  const { updateMode, ...meteringRest } = trip?.metering ? trip.metering : {};
  const canExport =
    updateMode === 'trip'
      ? !isEmpty(meteringRest?.overall)
      : !isEmpty(meteringRest?.legs);

  useEffect(() => {
    if (trip?.path) {
      setTripPath(trip.path);
    }
  }, [setTripPath, trip]);

  const tabs = [
    { key: 'flightTimes', title: 'Flight Times' },
    { key: 'trendMonitoring', title: 'Trend Monitoring' },
    { key: 'vorRvsm', title: 'VOR/RVSM' },
  ];

  const renderContent = () => {
    switch (activeTab.key) {
      case 'trendMonitoring':
        return (
          <TrendMonitoring
            trip={trip}
            aircraft={trip?.aircraft}
            handleSelectedLegChange={handleSelectedLegChange}
          />
        );
      case 'vorRvsm':
        return (
          <VorRvsm
            trip={trip}
            handleSelectedLegChange={handleSelectedLegChange}
          />
        );
      case 'flightTimes':
        return (
          <FlightTimes
            trip={trip}
            handleSelectedLegChange={handleSelectedLegChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box ph={2} pt={1}>
      {!aircraftLoading && canExport && (
        <Box ml="auto" dir="row" ai="center">
          <Text>Export Metering</Text>
          <Spacer dir="horizontal" size={1} />
          <Text onPress={() => exportMeteringFiles(trip, aircraft)}>
            <Icon name="csv-icon" size={25} />
          </Text>
        </Box>
      )}
      <TabsContainer
        trip={trip}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleUpdateModeChange={handleUpdateModeChange}
      />
      {renderContent()}
    </Box>
  );
};

/**
 * @file
 * A component for adding or editing a passenger
 *
 * @format
 * @flow strict-local
 */

import React, { useMemo, useRef } from 'react';
import { ViewStyle } from 'react-native';
import { useAsyncCallback } from 'react-async-hook';
import uuid from 'react-native-uuid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import Text from '@appComponents/Text';
import { Box } from '@appComponents/ScreenLayout';

import type { ActionMeta } from '@appComponents/SearchField';
import PersonEdit from './PersonEdit';
import { getUserData, useCompanyUsersFull } from '@appUtils/api';
import { userToPassengerData } from '@appUtils/passengers';
import { useAddPassenger } from '@appUtils/user';
import { UserRole } from '@appUtils/tripConverter';
import UserRoleIndicator, {
  LeadIndicator,
} from '@appComponents/UserRoleIndicator';

type PassengerProps = {
  passenger: PassengerItem,
  onUpdate: PassengerItem => void,
  isLead?: boolean,
  style?: ViewStyle,
  autoFocus?: boolean,
  selectedPassengers?: PassengerItem[],
  ownerId?: String,
  owner: Object,
  aircraft: Object,
};

export type PassengerItem = {
  name: string,
  id?: string,
};

const PassengerEdit = ({
  passenger,
  onUpdate,
  isLead,
  style,
  autoFocus = true,
  selectedPassengers = [],
  ownerId,
  owner,
  aircraft,
}: PassengerProps) => {
  const controller = useRef();

  const options = useOptions(ownerId, owner, aircraft);

  const addPassenger = useAddPassenger({ ownerId });
  const changeCallback = useChangeCallback({
    controller,
    passenger,
    onUpdate,
    addPassenger,
  });

  return (
    <PersonEdit
      person={passenger}
      onChange={changeCallback.execute}
      people={options.list}
      unavailablePeople={selectedPassengers}
      autoFocus={autoFocus}
      style={style}
      loading={options.loading}
      onMenuOpen={options.fetch}
      onMenuClose={passenger.name ? controller.current?.toggleEdit : undefined}
      controllerRef={controller}
      renderName={person => (
        <Box dir="row" ai="center">
          {person.name && <UserRoleIndicator user={person} />}
          <Text>{person.name}</Text>
          {isLead ? <LeadIndicator /> : null}
        </Box>
      )}
    />
  );
};

/**
 * Create new passenger record for the current owner in the DB
 * @param passenger
 */
const createPassenger = ({ addPassenger, passenger }) => {
  addPassenger(passenger);
  return passenger;
};

// Todo: hook with backend
/**
 * Add this passenger to the top of our recent passengers list
 * @param passenger
 * @returns {Promise<void>}
 */
const updateRecentlyUsed = async ({ name, id }) => {
  const storedData = await AsyncStorage.getItem('recent-passengers');
  let payload = [{ name, id }];

  if (storedData) {
    const storedList = JSON.parse(storedData);
    payload = storedList.filter(p => p.name !== name);
    payload.unshift({ name, id });
  }

  return AsyncStorage.setItem(
    'recent-passengers',
    JSON.stringify(payload.slice(0, 100)),
  );
};

const useChangeCallback = ({ controller, passenger, onUpdate, addPassenger }) =>
  useAsyncCallback(
    async (
      item: { label?: string, id?: string } | PassengerItem,
      actionMeta: ActionMeta,
    ) => {
      controller.current.setEditMode(false);

      let updates;

      if (actionMeta.action === 'create-option') {
        updates = createPassenger({
          addPassenger,
          passenger: { ...passenger, name: item.label, key: uuid.v4() },
        });
      } else if (actionMeta.action === 'select-option') {
        updates = { ...passenger, ...item };
      }

      if (updates) {
        onUpdate(updates);
        // await options.updateDefault(updates);
      }
    },
    [onUpdate, passenger],
  );

const useOptions = (ownerId, owner, aircraft) => {
  const ownerOptions = useOwnerPassengers(ownerId);
  const { data: companyOptions, loading: companyOptionsLoading } =
    useCompanyUsersFull({
      roles: [
        UserRole.OWNER,
        UserRole.PILOT,
        UserRole.MANAGER,
        UserRole.EMPLOYEE,
        UserRole.PASSENGER,
      ],
    });

  const list = useMemo(() => {
    const dataPassengers = ownerOptions.result ?? [];
    const companyUsers = [];
    if (companyOptions) {
      for (let u of companyOptions) {
        if (
          [UserRole.PILOT, UserRole.MANAGER, UserRole.EMPLOYEE].includes(u.role)
        ) {
          companyUsers.push(
            userToPassengerData({ user: u, ownerAssociated: false }),
          );
        } else if (u.role === UserRole.OWNER) {
          if (aircraft?.ownerIds?.includes(u.id)) {
            companyUsers.push(userToPassengerData({ user: u }));
          }
        } else if (u.role === UserRole.PASSENGER) {
          if (owner?.passengerIds?.includes(u.id)) {
            companyUsers.push(userToPassengerData({ user: u }));
          }
        }
      }
    }
    const all = [];
    dataPassengers?.map(p => {
      all.push({ ...p, ownerAssociated: true });
    });
    companyUsers?.map(p => {
      all.push(p);
    });

    return [
      /*
      {
        label: 'Recently Used',
        options: defaults,
      },
      */
      {
        // label: 'Other',
        options: all,
      },
    ];
  }, [
    aircraft?.ownerIds,
    companyOptions,
    owner?.passengerIds,
    ownerOptions.result,
  ]);

  return {
    list,
    loading: ownerOptions.loading || companyOptionsLoading,
    called: ownerOptions.status !== 'not-requested',
    fetch() {
      Promise.all([ownerOptions.execute()]).catch(console.error);
    },
    /*
    async updateDefault(updates) {
      await updateRecentlyUsed(updates);
      defaultOptions.reset();
      await defaultOptions.execute();
    },
    */
    exists(key, id) {
      return _.chain(list)
        .flatten()
        .some(
          p =>
            (key && p?.key && key === p?.key) || (id && p?.id && id === p?.id),
        )
        .value();
    },
  };
};

// Todo: hook with backend logic
const useRecentPassengers = () => {
  return useAsyncCallback(async () => {
    const storedData = await AsyncStorage.getItem('recent-passengers');
    if (storedData) {
      return JSON.parse(storedData);
    }

    return [];
  }, []);
};

const useOwnerPassengers = uid => {
  return useAsyncCallback(async () => {
    const ownerData = await getUserData(uid);
    if (ownerData?.passengers) {
      return ownerData.passengers;
    }
    return [];
  });
};

export default PassengerEdit;

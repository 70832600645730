/**
 * @file
 * Functions for exporting metering information related to a trip
 *
 * @format
 * @flow strict-local
 */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { forEach, isEmpty } from 'lodash';
import { arrayToCSV, fileTimestamp, getFileName } from '@appUtils/exports';

const getFlightTimeCSVData = ({ data, isHobbs, apu, numEngines, leg }) => {
  const csvHeaders = [''];
  const csvData = [leg > -1 ? `Leg ${leg}` : 'Overall'];

  const hobbsBeginning = data?.hobbsBeginning ?? '-';
  const hobbsEnding = data?.hobbsEnding ?? '-';
  const hobbsCurrent = data?.hobbsCurrent ?? '-';
  const airFrameBeginning = data?.airFrameBeginning ?? '-';
  const airFrameEnding = data?.airFrameEnding ?? '-';
  const airFrameCurrent = data?.airFrameCurrent ?? '-';
  const engine1Beginning = data?.engine1Beginning ?? '-';
  const engine1Ending = data?.engine1Ending ?? '-';
  const engine1Current = data?.engine1Current ?? '-';
  const engine1CyclesBeginning = data?.engine1CyclesBeginning ?? '-';
  const engine1CyclesEnding = data?.engine1CyclesEnding ?? '-';
  const engine1CyclesCurrent = data?.engine1CyclesCurrent ?? '-';
  const engine2Beginning = data?.engine2Beginning ?? '-';
  const engine2Ending = data?.engine2Ending ?? '-';
  const engine2Current = data?.engine2Current ?? '-';
  const engine2CyclesBeginning = data?.engine2CyclesBeginning ?? '-';
  const engine2CyclesEnding = data?.engine2CyclesEnding ?? '-';
  const engine2CyclesCurrent = data?.engine2CyclesCurrent ?? '-';
  const engine3Beginning = data?.engine3Beginning ?? '-';
  const engine3Ending = data?.engine3Ending ?? '-';
  const engine3Current = data?.engine3Current ?? '-';
  const engine3CyclesBeginning = data?.engine3CyclesBeginning ?? '-';
  const engine3CyclesEnding = data?.engine3CyclesEnding ?? '-';
  const engine3CyclesCurrent = data?.engine3CyclesCurrent ?? '-';
  const landingBeginning = data?.landingBeginning ?? '-';
  const landingEnding = data?.landingEnding ?? '-';
  const landingCurrent = data?.landingCurrent ?? '-';
  const apuBeginning = data?.apuBeginning ?? '-';
  const apuEnding = data?.apuEnding ?? '-';
  const apuCurrent = data?.apuCurrent ?? '-';
  const apuCycleBeginning = data?.apuCycleBeginning ?? '-';
  const apuCycleEnding = data?.apuCycleEnding ?? '-';
  const apuCycleCurrent = data?.apuCycleCurrent ?? '-';

  if (isHobbs) {
    leg < 2 &&
      csvHeaders.push(
        'Hobbs Beginning',
        'Hobbs Ending',
        'Hobbs Current',
        'Air Frame (HR) Beginning',
        'Air Frame (HR) Ending',
        'Air Frame (HR) Current',
      );
    csvData.push(
      hobbsBeginning,
      hobbsEnding,
      hobbsCurrent,
      airFrameBeginning,
      airFrameEnding,
      airFrameCurrent,
    );
  } else {
    leg < 2 &&
      csvHeaders.push(
        'Air Frame (HR) Beginning',
        'Air Frame (HR) Current',
        'Air Frame (HR) Ending',
      );
    csvData.push(airFrameBeginning, airFrameCurrent, airFrameEnding);
  }
  for (let i = 1; i <= numEngines; i++) {
    if (isHobbs) {
      leg < 2 &&
        csvHeaders.push(
          `Engine ${i} (HR) Beginning`,
          `Engine ${i} (HR) Ending`,
          `Engine ${i} (HR) Current`,
          `Engine ${i} Cycles Beginning`,
          `Engine ${i} Cycles Ending`,
          `Engine ${i} Cycles Current`,
        );
      switch (i) {
        case 3:
          csvData.push(
            engine3Beginning,
            engine3Ending,
            engine3Current,
            engine3CyclesBeginning,
            engine3CyclesEnding,
            engine3CyclesCurrent,
          );
          break;
        case 2:
          csvData.push(
            engine2Beginning,
            engine2Ending,
            engine2Current,
            engine2CyclesBeginning,
            engine2CyclesEnding,
            engine2CyclesCurrent,
          );
          break;
        case 1:
        default:
          csvData.push(
            engine1Beginning,
            engine1Ending,
            engine1Current,
            engine1CyclesBeginning,
            engine1CyclesEnding,
            engine1CyclesCurrent,
          );
      }
    } else {
      leg < 2 &&
        csvHeaders.push(
          `Engine ${i} (HR) Beginning`,
          `Engine ${i} (HR) Current`,
          `Engine ${i} (HR) Ending`,
          `Engine ${i} Cycles Beginning`,
          `Engine ${i} Cycles Current`,
          `Engine ${i} Cycles Ending`,
        );
      switch (i) {
        case 3:
          csvData.push(
            engine3Beginning,
            engine3Current,
            engine3Ending,
            engine3CyclesBeginning,
            engine3CyclesCurrent,
            engine3CyclesEnding,
          );
          break;
        case 2:
          csvData.push(
            engine2Beginning,
            engine2Current,
            engine2Ending,
            engine2CyclesBeginning,
            engine2CyclesCurrent,
            engine2CyclesEnding,
          );
          break;
        case 1:
        default:
          csvData.push(
            engine1Beginning,
            engine1Current,
            engine1Ending,
            engine1CyclesBeginning,
            engine1CyclesCurrent,
            engine1CyclesEnding,
          );
      }
    }
  }
  if (isHobbs) {
    leg < 2 &&
      csvHeaders.push(
        'Landings Beginning',
        'Landings Ending',
        'Landings Current',
      );
    csvData.push(landingBeginning, landingEnding, landingCurrent);
  } else {
    leg < 2 &&
      csvHeaders.push(
        'Landings Beginning',
        'Landings Current',
        'Landings Ending',
      );
    csvData.push(landingBeginning, landingCurrent, landingEnding);
  }
  if (apu === 'Hobbs') {
    leg < 2 &&
      csvHeaders.push(
        'APU (HR) Beginning',
        'APU (HR) Ending',
        'APU (HR) Current',
        'APU Cycles Beginning',
        'APU Cycles Ending',
        'APU Cycles Current',
      );
    csvData.push(
      apuBeginning,
      apuEnding,
      apuCurrent,
      apuCycleBeginning,
      apuCycleEnding,
      apuCycleCurrent,
    );
  } else if (apu === 'FMS') {
    leg < 2 &&
      csvHeaders.push(
        'APU (HR) Beginning',
        'APU (HR) Current',
        'APU (HR) Ending',
        'APU Cycles Beginning',
        'APU Cycles Current',
        'APU Cycles Ending',
      );
    csvData.push(
      apuBeginning,
      apuCurrent,
      apuEnding,
      apuCycleBeginning,
      apuCycleCurrent,
      apuCycleEnding,
    );
  }

  if (leg < 2) {
    return [csvHeaders, csvData];
  } else {
    return csvData;
  }
};

const getTrendMonitoringCSVData = ({ data, numEngines, leg }) => {
  const csvHeaders = [''];
  const csvData = [leg > -1 ? `Leg ${leg}` : 'Overall'];

  const rat = data?.rat ?? '-';
  const altitudeAtRat = data?.altitudeAtRat ?? '-';
  const mach = data?.mach ?? '-';
  const engine1N1 = data?.engine1N1 ?? '-';
  const engine1N2 = data?.engine1N2 ?? '-';
  const engine1Torque = data?.engine1Torque ?? '-';
  const engine1ITT = data?.engine1ITT ?? '-';
  const engine1FuelFlow = data?.engine1FuelFlow ?? '-';
  const engine1OilPressure = data?.engine1OilPressure ?? '-';
  const engine1OilTemp = data?.engine1OilTemp ?? '-';
  const engine2N1 = data?.engine2N1 ?? '-';
  const engine2N2 = data?.engine2N2 ?? '-';
  const engine2Torque = data?.engine2Torque ?? '-';
  const engine2ITT = data?.engine2ITT ?? '-';
  const engine2FuelFlow = data?.engine2FuelFlow ?? '-';
  const engine2OilPressure = data?.engine2OilPressure ?? '-';
  const engine2OilTemp = data?.engine2OilTemp ?? '-';
  const engine3N1 = data?.engine3N1 ?? '-';
  const engine3N2 = data?.engine3N2 ?? '-';
  const engine3Torque = data?.engine3Torque ?? '-';
  const engine3ITT = data?.engine3ITT ?? '-';
  const engine3FuelFlow = data?.engine3FuelFlow ?? '-';
  const engine3OilPressure = data?.engine3OilPressure ?? '-';
  const engine3OilTemp = data?.engine3OilTemp ?? '-';

  leg < 2 && csvHeaders.push('RAT', 'Altitude at RAT', 'Mach');
  csvData.push(rat, altitudeAtRat, mach);

  for (let i = 1; i <= numEngines; i++) {
    leg < 2 &&
      csvHeaders.push(
        `Engine ${i} N1 (%)`,
        `Engine ${i} N2/Prop (%)`,
        `Engine ${i} Torque (%)`,
        `Engine ${i} ITT (°C)`,
        `Engine ${i} Fuel Flow (lb)`,
        `Engine ${i} Oil Pressure (PSI)`,
        `Engine ${i} Oil Temp (°C)`,
      );
    switch (i) {
      case 3:
        csvData.push(
          engine3N1,
          engine3N2,
          engine3Torque,
          engine3ITT,
          engine3FuelFlow,
          engine3OilPressure,
          engine3OilTemp,
        );
        break;
      case 2:
        csvData.push(
          engine2N1,
          engine2N2,
          engine2Torque,
          engine2ITT,
          engine2FuelFlow,
          engine2OilPressure,
          engine2OilTemp,
        );
        break;
      case 1:
      default:
        csvData.push(
          engine1N1,
          engine1N2,
          engine1Torque,
          engine1ITT,
          engine1FuelFlow,
          engine1OilPressure,
          engine1OilTemp,
        );
    }
  }

  if (leg < 2) {
    return [csvHeaders, csvData];
  } else {
    return csvData;
  }
};

const getVORRVSMCSVData = ({ data, leg }) => {
  const csvHeaders = [''];
  const csvData = [leg > -1 ? `Leg ${leg}` : 'Overall'];

  const vorCheck = data?.vorCheck ?? '-';
  const rvsm = data?.rvsm ?? '-';
  const readingTaken = data?.readingTaken ?? '-';
  const vor1 = data?.vor1 ?? '-';
  const vor2 = data?.vor2 ?? '-';
  const difference = data?.vorDifference ?? '-';

  leg < 2 &&
    csvHeaders.push(
      'VOR Check',
      'Altitude at RVSM (ft)',
      'Reading Taken',
      '#1 (°)',
      '#2 (°)',
      'Difference',
    );
  csvData.push(vorCheck, rvsm, readingTaken, vor1, vor2, difference);

  if (leg < 2) {
    return [csvHeaders, csvData];
  } else {
    return csvData;
  }
};

const exportMeteringFiles = async (trip, aircraft) => {
  if (trip?.metering && trip?.aircraft) {
    const tripName = `${trip?.identifier} - ${trip?.customName ?? ''}`;
    const data = trip.metering;
    const updateMode = data?.updateMode;
    const isHobbs = aircraft?.meteringSystem === 'Hobbs';
    const apu = ['Hobbs', 'FMS'].includes(aircraft?.apuMeteringSystem)
      ? aircraft.apuMeteringSystem
      : null;
    const numEngines = aircraft?.numEngines;
    const flightTimeFileName = getFileName(tripName, 'csv', 'Flight Times');
    const trendMonitoringFileName = getFileName(
      tripName,
      'csv',
      'Trend Monitoring',
    );
    const vorRvsmFileName = getFileName(tripName, 'csv', 'VORRVSM');
    const zip = new JSZip();
    let hasFiles = false;

    if (updateMode === 'trip') {
      // Flight Times
      if (!isEmpty(data?.overall?.flightTimes)) {
        const flightTimeData = getFlightTimeCSVData({
          data: data.overall.flightTimes,
          isHobbs,
          apu,
          numEngines,
          leg: -1,
        });
        zip.file(flightTimeFileName, arrayToCSV(flightTimeData));
        hasFiles = true;
      }

      // Trend Monitoring
      if (!isEmpty(data?.overall?.trendMonitoring)) {
        const trendMonitoringData = getTrendMonitoringCSVData({
          data: data.overall.trendMonitoring,
          numEngines,
          leg: -1,
        });
        zip.file(trendMonitoringFileName, arrayToCSV(trendMonitoringData));
        hasFiles = true;
      }

      // VOR/RVSM
      if (!isEmpty(data?.overall?.vorRvsm)) {
        const vorRvsmData = getVORRVSMCSVData({
          data: data.overall.vorRvsm,
          leg: -1,
        });
        zip.file(vorRvsmFileName, arrayToCSV(vorRvsmData));
        hasFiles = true;
      }
    } else if (updateMode === 'leg') {
      if (!isEmpty(data?.legs)) {
        let flightTimeData = [];
        let trendMonitoringData = [];
        let vorRvsmData = [];
        forEach(data.legs, (leg, i) => {
          // Flight Times
          if (leg?.flightTimes) {
            const flightTimes = getFlightTimeCSVData({
              data: leg.flightTimes,
              isHobbs,
              apu,
              numEngines,
              leg: Number(i) + 1,
            });
            if (i === '0') {
              flightTimeData = flightTimes;
            } else {
              flightTimeData.push(flightTimes);
            }
          }
          if (!isEmpty(flightTimeData)) {
            zip.file(flightTimeFileName, arrayToCSV(flightTimeData));
            hasFiles = true;
          }

          // Trend Monitoring
          if (!isEmpty(leg?.trendMonitoring)) {
            const trendMonitoring = getTrendMonitoringCSVData({
              data: leg.trendMonitoring,
              numEngines,
              leg: Number(i) + 1,
            });
            if (i === '0') {
              trendMonitoringData = trendMonitoring;
            } else {
              trendMonitoringData.push(trendMonitoring);
            }
            if (!isEmpty(trendMonitoringData)) {
              zip.file(
                trendMonitoringFileName,
                arrayToCSV(trendMonitoringData),
              );
              hasFiles = true;
            }
          }

          // VOR/RVSM
          if (!isEmpty(leg?.vorRvsm)) {
            const vorRvsm = getVORRVSMCSVData({
              data: leg.vorRvsm,
              leg: Number(i) + 1,
            });
            if (i === '0') {
              vorRvsmData = vorRvsm;
            } else {
              vorRvsmData.push(vorRvsm);
            }
            if (!isEmpty(vorRvsmData)) {
              zip.file(vorRvsmFileName, arrayToCSV(vorRvsmData));
              hasFiles = true;
            }
          }
        });
      }
    }

    if (hasFiles) {
      zip.generateAsync({ type: 'blob' }).then(zipFile => {
        saveAs(zipFile, `${tripName} Metering Data ${fileTimestamp()}.zip`);
      });
    }
  }
};

export default exportMeteringFiles;

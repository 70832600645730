import React from 'react';
import { View, StyleSheet, ScrollView, Modal } from 'react-native';

import Button from '@appComponents/Button';
import { Box } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import {
  DecimalField,
  EngineField,
  IntegerField,
} from '@appComponents/Metering/Fields';
import { useMeteringContext } from '@appUtils/context/MeteringContext';

export const TrendMonitoringModal = ({ onClose, aircraft, updateMode }) => {
  const theme = useTheme();
  const { control, saveMeteringData, isSaving, selectedLeg, setValue } =
    useMeteringContext();

  const renderEngineFields = engineNumber => (
    <View key={`engine-${engineNumber}`} style={styles.engineColumn}>
      <Text style={styles.engineHeader}>ENGINE {engineNumber}</Text>
      <View
        style={[styles.separator, { backgroundColor: theme.colors.text }]}
      />
      <Box mr={1}>
        <EngineField
          name={`trendMonitoring.engine${engineNumber}N1`}
          label="N1 %"
          control={control}
        />
        <EngineField
          name={`trendMonitoring.engine${engineNumber}N2`}
          label="N2/Prop %"
          control={control}
        />
        <EngineField
          name={`trendMonitoring.engine${engineNumber}Torque`}
          label="TORQUE %"
          control={control}
        />
        <EngineField
          name={`trendMonitoring.engine${engineNumber}ITT`}
          label="ITT °C"
          control={control}
        />
        <EngineField
          name={`trendMonitoring.engine${engineNumber}FuelFlow`}
          label="FUEL FLOW LB"
          control={control}
        />
        <EngineField
          name={`trendMonitoring.engine${engineNumber}OilPressure`}
          label="OIL PRESSURE PSI"
          control={control}
        />
        <EngineField
          name={`trendMonitoring.engine${engineNumber}OilTemp`}
          label="OIL TEMP °C"
          control={control}
        />
      </Box>
    </View>
  );

  return (
    <Modal visible={true} animationType="fade" transparent={true}>
      <View style={styles.modalContainer}>
        <View
          style={[
            styles.modalContent,
            { backgroundColor: theme.colors.background },
          ]}>
          <Text style={styles.modalTitle}>
            {updateMode === 'leg'
              ? `EDIT LEG ${selectedLeg?.from} > ${selectedLeg?.to}`
              : 'EDIT TREND MONITORING'}
          </Text>
          <ScrollView style={styles.scrollView}>
            <Box mt={2}>
              <View style={styles.topFieldsContainer}>
                <View style={styles.topField}>
                  <IntegerField
                    name="trendMonitoring.rat"
                    label="RAT"
                    control={control}
                    allowNegative={true}
                    style={{
                      backgroundColor: theme.colors.fieldBackground,
                    }}
                  />
                </View>
                <View style={styles.topField}>
                  <IntegerField
                    name="trendMonitoring.altitudeAtRat"
                    label="ALTITUDE AT RAT"
                    control={control}
                    allowNegative={true}
                    style={{
                      backgroundColor: theme.colors.fieldBackground,
                    }}
                  />
                </View>
                <View style={styles.topField}>
                  <DecimalField
                    name="trendMonitoring.mach"
                    label="MACH"
                    control={control}
                    decimalPlaces={2}
                    style={{
                      backgroundColor: theme.colors.fieldBackground,
                    }}
                  />
                </View>
              </View>

              <View style={styles.engineContainer}>
                {[...Array(Math.min(aircraft.numEngines, 3))].map((_, i) =>
                  renderEngineFields(i + 1),
                )}
              </View>
            </Box>
          </ScrollView>
          <View style={styles.buttonContainer}>
            <Button onPress={onClose} color="error">
              CANCEL
            </Button>
            <Button
              loading={isSaving}
              disabled={isSaving}
              ml={1}
              mr={1}
              onPress={() => {
                saveMeteringData();
                onClose();
              }}>
              SAVE
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '90%',
    maxWidth: 800,
    height: '80%',
    borderRadius: 10,
    padding: 20,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
    textTransform: 'uppercase',
  },
  scrollView: {
    maxHeight: '80%',
  },
  topFieldsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  topField: {
    width: '32%',
    marginBottom: 10,
  },
  engineContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  engineColumn: {
    width: '32%',
    marginBottom: 20,
  },
  engineHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  separator: {
    height: 1,
    marginBottom: 10,
    marginVertical: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginLeft: 10,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  saveLoader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 32,
  },
});

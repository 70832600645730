import React from 'react';
import { ActivityIndicator } from 'react-native';
import Tabs from '@appComponents/Tabs';
import { RadioButtonField } from '@appComponents/Metering/RadioButtonField';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { Box } from '@appComponents/ScreenLayout';

export const TabsContainer = ({
  tabs,
  activeTab,
  setActiveTab,
  handleUpdateModeChange,
}) => {
  const { control, saveMeteringDataMode, isSaving } = useMeteringContext();

  const onChangeInterceptor = value => {
    saveMeteringDataMode(value);
    handleUpdateModeChange(value);
  };

  return (
    <Box>
      <Tabs
        tabIndex={tabs.findIndex(tab => tab.key === activeTab.key)}
        onTabPress={({ route }) => setActiveTab(route)}
        routes={tabs}
        tabBarInline
        onIndexChange={() => null}
      />
      <Box dir="row" ai="center" jc="flex-start">
        <RadioButtonField
          name="updateMode"
          control={control}
          options={[
            { value: 'leg', label: 'Update for each leg' },
            { value: 'trip', label: 'Update for trip' },
          ]}
          defaultValue="trip"
          onChangeInterceptor={onChangeInterceptor}
        />
        {isSaving && <ActivityIndicator size="small" />}
      </Box>
    </Box>
  );
};

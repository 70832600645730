import React, { useMemo } from 'react';
import { View, StyleSheet, ScrollView, Modal } from 'react-native';
import { useWatch } from 'react-hook-form';

import Button from '@appComponents/Button';
import { Box } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import { DecimalField, IntegerField } from '@appComponents/Metering/Fields';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { useCycleIncrement } from '@appUtils/hooks/metering/useCycleIncrement';
import { useFlightTimesCalculations } from '@appUtils/hooks/metering/useFlightTimesCalculations';

export const FlightTimesModal = ({ onClose, trip, aircraft, updateMode }) => {
  const { control, setValue, saveMeteringData, isSaving, selectedLeg } =
    useMeteringContext();
  const theme = useTheme();

  const isHobbs = aircraft?.meteringSystem
    ? aircraft?.meteringSystem === 'Hobbs'
    : true;
  const isAPUHobbs = aircraft?.apuMeteringSystem === 'Hobbs';
  const showAPU = ['Hobbs', 'FMS'].includes(aircraft?.apuMeteringSystem);
  const watchFlightTimes = useWatch({
    control,
    name: 'flightTimes',
    defaultValue: {},
  });

  useCycleIncrement({
    setValue,
    trip,
    aircraft,
    isLeg: updateMode === 'leg',
    watchFlightTimes,
  });

  useFlightTimesCalculations({
    watchFlightTimes,
    isHobbs,
    isAPUHobbs,
    setValue,
    trip,
    aircraft,
  });

  const renderField = (name, isInteger = false, isCalculated = false) => {
    return (
      <Box flex={1} mh={1} key={name}>
        {isInteger ? (
          <IntegerField
            name={name}
            control={control}
            outlineColor={
              isCalculated ? theme.colors.background : theme.colors.light
            }
            style={[
              styles.input,
              isCalculated ? styles.calculatedInput : {},
              {
                backgroundColor: isCalculated
                  ? theme.colors.background
                  : theme.colors.fieldBackground,
              },
            ]}
            disabled={isCalculated}
            mode={!isCalculated ? 'outlined' : 'flat'}
          />
        ) : (
          <DecimalField
            name={name}
            control={control}
            decimalPlaces={1}
            style={[
              styles.input,
              isCalculated ? styles.calculatedInput : {},
              isCalculated
                ? { backgroundColor: theme.colors.background }
                : {
                    backgroundColor: theme.colors.fieldBackground,
                  },
            ]}
            disabled={isCalculated}
            mode={!isCalculated ? 'outlined' : 'flat'}
          />
        )}
      </Box>
    );
  };

  const renderSection = (title, fields, isAPUSection = false) => (
    <View style={styles.section} key={title}>
      <Text style={[styles.sectionTitle, { color: theme.colors.text }]}>
        {title}
      </Text>
      <View style={styles.fieldsContainer}>
        {fields.map(({ name, isInteger }) =>
          renderField(
            name,
            isInteger,
            ((isAPUSection ? isAPUHobbs : isHobbs) &&
              name.endsWith('Current')) ||
              ((isAPUSection ? !isAPUHobbs : !isHobbs) &&
                name.endsWith('Ending')),
          ),
        )}
      </View>
    </View>
  );

  const renderHeader = meteringSystem => (
    <View style={styles.header}>
      <Text style={[styles.headerText, { color: theme.colors.text }]}>
        BEGINNING
      </Text>
      <Text style={[styles.headerText, { color: theme.colors.text }]}>
        {meteringSystem === 'Hobbs' ? 'ENDING' : 'CURRENT'}
      </Text>
      <Text style={[styles.headerText, { color: theme.colors.text }]}>
        {meteringSystem === 'Hobbs' ? 'CURRENT' : 'ENDING'}
      </Text>
    </View>
  );

  const sections = useMemo(
    () => [
      ...(isHobbs
        ? [
            {
              title: 'HOBBS (HR)',
              fields: [
                { name: 'flightTimes.hobbsBeginning' },
                { name: 'flightTimes.hobbsEnding' },
                { name: 'flightTimes.hobbsCurrent' },
              ],
            },
          ]
        : []),
      {
        title: 'AIR FRAME (HR)',
        fields: [
          { name: 'flightTimes.airFrameBeginning' },
          {
            name: isHobbs
              ? 'flightTimes.airFrameEnding'
              : 'flightTimes.airFrameCurrent',
          },
          {
            name: isHobbs
              ? 'flightTimes.airFrameCurrent'
              : 'flightTimes.airFrameEnding',
          },
        ],
      },
      ...[...Array(aircraft?.numEngines)].flatMap((_, i) => [
        {
          title: `ENGINE ${i + 1} (HR)`,
          fields: [
            { name: `flightTimes.engine${i + 1}Beginning` },
            {
              name: isHobbs
                ? `flightTimes.engine${i + 1}Ending`
                : `flightTimes.engine${i + 1}Current`,
            },
            {
              name: isHobbs
                ? `flightTimes.engine${i + 1}Current`
                : `flightTimes.engine${i + 1}Ending`,
            },
          ],
        },
        {
          title: `ENGINE ${i + 1} CYCLES`,
          fields: [
            {
              name: `flightTimes.engine${i + 1}CyclesBeginning`,
              isInteger: true,
            },
            {
              name: isHobbs
                ? `flightTimes.engine${i + 1}CyclesEnding`
                : `flightTimes.engine${i + 1}CyclesCurrent`,
              isInteger: true,
            },
            {
              name: isHobbs
                ? `flightTimes.engine${i + 1}CyclesCurrent`
                : `flightTimes.engine${i + 1}CyclesEnding`,
              isInteger: true,
            },
          ],
        },
      ]),
      {
        title: 'LANDINGS',
        fields: [
          { name: 'flightTimes.landingBeginning', isInteger: true },
          {
            name: isHobbs
              ? 'flightTimes.landingEnding'
              : 'flightTimes.landingCurrent',
            isInteger: true,
          },
          {
            name: isHobbs
              ? 'flightTimes.landingCurrent'
              : 'flightTimes.landingEnding',
            isInteger: true,
          },
        ],
      },
      ...(showAPU
        ? [
            {
              title: 'APU_SECTION',
              isAPUSection: true,
              sections: [
                {
                  title: 'APU (HR)',
                  fields: [
                    { name: 'flightTimes.apuBeginning' },
                    {
                      name: isAPUHobbs
                        ? 'flightTimes.apuEnding'
                        : 'flightTimes.apuCurrent',
                    },
                    {
                      name: isAPUHobbs
                        ? 'flightTimes.apuCurrent'
                        : 'flightTimes.apuEnding',
                    },
                  ],
                },
                {
                  title: 'APU CYCLES',
                  fields: [
                    { name: 'flightTimes.apuCycleBeginning', isInteger: true },
                    {
                      name: isAPUHobbs
                        ? 'flightTimes.apuCycleEnding'
                        : 'flightTimes.apuCycleCurrent',
                      isInteger: true,
                    },
                    {
                      name: isAPUHobbs
                        ? 'flightTimes.apuCycleCurrent'
                        : 'flightTimes.apuCycleEnding',
                      isInteger: true,
                    },
                  ],
                },
              ],
            },
          ]
        : []),
    ],
    [isHobbs, isAPUHobbs, aircraft?.numEngines, showAPU],
  );

  return (
    <Modal animationType="fade" transparent={true}>
      <View style={styles.modalContainer}>
        <View
          style={[
            styles.modalContent,
            { backgroundColor: theme.colors.background },
          ]}>
          <Text style={styles.modalTitle}>
            {updateMode === 'leg'
              ? `EDIT LEG ${selectedLeg?.from} > ${selectedLeg?.to}`
              : 'EDIT FLIGHT TIMES'}
          </Text>
          <ScrollView style={styles.scrollView}>
            <Box mt={2}>
              {renderHeader(aircraft?.meteringSystem)}
              <View
                style={[
                  styles.headerSeparator,
                  { backgroundColor: theme.colors.text },
                ]}
              />
              {sections.map(section => {
                if (section.isAPUSection) {
                  return (
                    <React.Fragment key={section.title}>
                      {showAPU &&
                        isHobbs !== isAPUHobbs &&
                        renderHeader(aircraft?.apuMeteringSystem)}
                      {section.sections.map(subSection =>
                        renderSection(
                          subSection.title,
                          subSection.fields,
                          true,
                        ),
                      )}
                    </React.Fragment>
                  );
                }
                return renderSection(section.title, section.fields);
              })}
            </Box>
          </ScrollView>
          <View style={styles.buttonContainer}>
            <Button onPress={onClose} color="error">
              CANCEL
            </Button>
            <Button
              loading={isSaving}
              disabled={isSaving}
              ml={1}
              mr={1}
              onPress={() => {
                saveMeteringData();
                onClose();
              }}>
              SAVE
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '90%',
    maxWidth: 800,
    height: '80%',
    borderRadius: 10,
    padding: 20,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
    textTransform: 'uppercase',
  },
  scrollView: {
    maxHeight: '80%',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
    marginBottom: 32,
  },
  headerText: {
    fontSize: 12,
    flex: 1,
    textAlign: 'center',
  },
  headerSeparator: {
    height: 1,
    marginBottom: 10,
  },
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 5,
    paddingLeft: 16,
  },
  fieldsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    color: 'red',
  },
  calculatedInput: {
    textAlign: 'center',
  },
  textField: {
    paddingVertical: 14,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme => theme.colors.fieldBackground,
    borderRadius: 4,
  },
  textValue: {
    fontSize: 16,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginLeft: 10,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  saveLoader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 32,
  },
});

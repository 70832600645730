import React, { useState } from 'react';
import Dialog from '../../components/Dialog';
import { View } from 'react-native-web';
import Button from '@appComponents/Button';
import Text from '@appComponents/Text';
import { FitToContentButton } from '@appComponents/Button';
import styled from '@emotion/native';
import { usePaymentFees } from '@appUtils/api';
import { calculatePaymentFees } from '@appUtils/payment';

const PaymentMethodSelectDialog = ({
  title,
  modalTitle,
  amount,
  disabled = false,
  setSendingPayment,
  sendingPayment,
  paymentHandler,
}) => {
  const [visible, setVisible] = useState(false);
  const paymentFees = usePaymentFees();

  if (paymentFees.loading) {
    return <></>;
  }

  const cardAmount = calculatePaymentFees(
    amount,
    paymentFees.data.find(fee => fee.id === 'card'),
    'card',
  );
  const bankAmount = calculatePaymentFees(
    amount,
    paymentFees.data.find(fee => fee.id === 'us_bank_account'),
    'us_bank_account',
  );

  return (
    <>
      <FitToContentButton
        style={{ backgroundColor: '#0094FF' }}
        title={title}
        onPress={() => setVisible(true)}>
        {title}
      </FitToContentButton>
      <Dialog
        visible={visible}
        title={modalTitle}
        onDismiss={() => setVisible(false)}
        actionSlot={
          <View style={{ flexDirection: 'row', gap: 8 }}>
            <Button color="error" onPress={() => setVisible(false)}>
              Cancel
            </Button>
          </View>
        }>
        <PaymentContainer>
          <Text>Choose a payment method</Text>

          <PaymentOption>
            <PaymentContent>
              <PaymentInfo>
                <PaymentTitle>Card Payment</PaymentTitle>
                <FeeText>Subtotal: ${amount.toFixed(2)}</FeeText>
                <FeeText>Fees: ${(cardAmount - amount).toFixed(2)}</FeeText>
                <TotalText>Total: ${cardAmount.toFixed(2)}</TotalText>
              </PaymentInfo>
              <Button
                disabled={disabled || sendingPayment}
                loading={sendingPayment}
                onPress={() => {
                  setSendingPayment(true);
                  paymentHandler(cardAmount, cardAmount - amount, 'card');
                }}>
                Card Payment
              </Button>
            </PaymentContent>
          </PaymentOption>

          <PaymentOption>
            <PaymentContent>
              <PaymentInfo>
                <PaymentTitle>Bank Account Payment</PaymentTitle>
                <FeeText>Subtotal: ${amount.toFixed(2)}</FeeText>
                <FeeText>Fees: ${(bankAmount - amount).toFixed(2)}</FeeText>
                <TotalText>Total: ${bankAmount.toFixed(2)}</TotalText>
              </PaymentInfo>
              <Button
                disabled={disabled || sendingPayment}
                loading={sendingPayment}
                onPress={() => {
                  setSendingPayment(true);
                  paymentHandler(
                    bankAmount,
                    bankAmount - amount,
                    'us_bank_account',
                  );
                }}>
                Bank Account Payment
              </Button>
            </PaymentContent>
          </PaymentOption>
        </PaymentContainer>
      </Dialog>
    </>
  );
};

const PaymentContent = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PaymentInfo = styled(View)`
  flex: 1;
`;

const PaymentContainer = styled(View)`
  padding: 16px;
  gap: 16px;
`;

const PaymentOption = styled(View)`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
`;

const PaymentTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const FeeText = styled(Text)`
  color: #e0e0e0;
  margin-bottom: 4px;
`;

const TotalText = styled(Text)`
  font-weight: 600;
  color: #0094ff;
  font-size: 18px;
`;

export default PaymentMethodSelectDialog;

import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useMeteringContext } from '../../context/MeteringContext';

const defaultValues = {
  vorRvsm: {},
  trendMonitoring: {},
  flightTimes: {},
  updateMode: 'trip',
};

export const useDefaultValues = trip => {
  const { reset, saveMeteringData, setIsLoading, setSelectedLeg, selectedLeg } =
    useMeteringContext();

  const resetDefaultValues = useCallback(() => {
    if (isEmpty(trip)) {
      return;
    }
    setIsLoading(true);

    const baseLeg = trip.legs?.[0];

    if (trip?.metering?.updateMode === 'leg' && selectedLeg?.index === -1) {
      setSelectedLeg({
        index: 0,
        value: 0,
        label: `${baseLeg?.from} ------ ${baseLeg?.to}`,
      });
    }

    if (trip?.metering?.updateMode === 'trip' && selectedLeg?.index !== -1) {
      setSelectedLeg({
        index: -1,
        value: -1,
      });
    }

    const relevantData =
      trip?.metering?.updateMode === 'leg'
        ? trip?.metering?.legs?.[selectedLeg?.index || 0] || defaultValues
        : trip?.metering?.overall || defaultValues;

    reset({
      ...relevantData,
      updateMode: trip?.metering?.updateMode,
    });
    setIsLoading(false);
  }, [reset, selectedLeg?.index, setIsLoading, setSelectedLeg, trip]);

  useEffect(() => {
    resetDefaultValues();
  }, [resetDefaultValues, trip, selectedLeg?.index]);

  const handleUpdateModeChange = newUpdateMode => {
    setIsLoading(true);

    if (newUpdateMode === 'leg') {
      setSelectedLeg({
        index: 0,
        id: 0,
        value: 0,
        label: `${trip?.legs?.[0]?.from} ------ ${trip?.legs?.[0]?.to}`,
      });
    } else {
      setSelectedLeg({
        index: -1,
        id: -1,
        value: -1,
      });
    }

    try {
      const relevantData =
        newUpdateMode === 'leg'
          ? trip?.metering?.legs?.[selectedLeg?.index || 0] || defaultValues
          : trip?.metering?.overall || defaultValues;

      reset({ ...relevantData, updateMode: newUpdateMode });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedLegChange = (newSelectedLeg, isDirty = false) => {
    try {
      setIsLoading(true);
      if (isDirty) {
        saveMeteringData();
      }

      const legIndex = newSelectedLeg?.index ?? 0;
      const legData = trip?.metering?.legs?.[legIndex] || defaultValues;

      reset({ ...legData, updateMode: 'leg' });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUpdateModeChange,
    handleSelectedLegChange,
  };
};

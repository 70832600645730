import { useEffect, useState, useCallback } from 'react';
import { rawTimeZones } from '@vvo/tzdb';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { createDraft, getUserData } from '@appUtils/api';
import tripConverter, {
  TripState,
  OwnerState,
  UserRole,
} from './tripConverter';
import { Platform } from 'react-native';
import { defaultChecklistItems } from './constants/index';
import app from '@appFirebase/app';

export const saveTrip = async (payload, doc, skipDeparture = false) => {
  if (!doc) {
    const { id, firstName, lastName, phoneNumber, role } = await getUserData();

    if (role === UserRole.OWNER) {
      doc = await createDraft({
        state: TripState.OWNER_DRAFT,
        owner: {
          id,
          firstName,
          lastName,
          name: `${firstName} ${lastName}`,
          phoneNumber,
          role,
          state: OwnerState.OWNER_DRAFT,
        },
        archived: false,
      });
    } else {
      doc = await createDraft({
        state: TripState.PILOT_DRAFT,
        archived: false,
      });
    }
  }

  const { legs, ...rest } = payload;
  rest.legs = skipDeparture ? legs : legs?.map(setDepartureDate);
  const data = tripConverter.toFirestore(rest);
  await doc.update(data);
  return doc;
};

const setDepartureDate = leg => {
  const { date, time, timeZone, ...rest } = leg;
  const zone = rawTimeZones.find(
    tz => tz.countryCode === 'US' && tz.abbreviation === timeZone,
  );

  const departureDate = DateTime.fromJSDate(leg.date)
    .setZone(zone?.name ?? 'local')
    .set({ hour: time.getHours(), minute: time.getMinutes(), second: 0 });

  return {
    ...rest,
    departureDate: departureDate,
  };
};

export const flightTimeToMinutes = timeStr => {
  if (!timeStr) {
    return 0;
  }

  const [hours = 0, minutes = 0] = timeStr.split(':').map(Number);
  return minutes + hours * 60;
};

export const minutesToFlightTime = (totalMinutes, format = 'implicit') => {
  if (!totalMinutes) {
    return '';
  }

  const hours = Math.floor(totalMinutes / 60);
  const minutes = (totalMinutes % 60).toString().padEnd(2, '0');

  if (format === 'explicit') {
    if (minutes > 0) {
      return `${hours}h ${minutes}m`;
    }

    return `${hours}h`;
  }

  return `${hours}:${minutes}`;
};

export const getTripStateName = (state, defaultText) => {
  switch (state) {
    case TripState.OWNER_DRAFT:
    case TripState.PILOT_DRAFT:
      return 'Trip Builder - Review';
    case TripState.OWNER_REQUEST:
    case TripState.PILOT_REQUEST:
      return 'Requested Trip';
    case TripState.DRAFT:
      return Platform.OS === 'web' ? 'Draft Trip' : 'Requested Trip';
    case TripState.UPCOMING:
      return 'Upcoming Trip';
    case TripState.ACTIVE:
      return 'Active Trip';
    case TripState.ENDED:
      return 'Completed Trip';
    case TripState.CANCELLED:
      return 'Cancelled Trip';
    default:
      return defaultText;
  }
};

export const isOwnerOnTrip = (trip, user) => {
  if (user?.role !== UserRole.OWNER) {
    return false;
  }
  const userName = `${user?.firstName} ${user?.lastName}`;
  const ownerName = `${trip?.owner?.firstName} ${trip?.owner?.lastName}`;
  return userName === ownerName;
};

export const isPicOnTrip = (trip, user) => {
  if (user?.role === UserRole.OWNER || user?.role === UserRole.PASSENGER) {
    return false;
  }
  const userName = `${user?.firstName} ${user?.lastName}`;
  const picName =
    trip.pilots && trip.pilots.length > 0
      ? `${trip.pilots[0]?.firstName} ${trip.pilots[0]?.lastName}`
      : '';
  return userName === picName;
};

export const isSicOnTrip = (trip, user) => {
  if (user?.role === UserRole.OWNER || user?.role === UserRole.PASSENGER) {
    return false;
  }
  const userName = `${user?.firstName} ${user?.lastName}`;
  const sicName =
    trip.pilots && trip.pilots.length > 1
      ? `${trip.pilots[1]?.firstName} ${trip.pilots[1]?.lastName}`
      : '';
  return userName === sicName;
};

export const getTripPassengers = legs =>
  _.chain(legs)
    .map('passengers')
    .flatten()
    .uniqWith(
      (a, b) =>
        (a.key && b.key && a.key === b.key) || (a.id && b.id && a.id === b.id),
    )
    .value();

export async function saveMeteringData(
  meteringData,
  docPath,
  legIndex = -1,
  updateMode = 'leg',
) {
  try {
    const doc = app.firestore().doc(docPath);

    const docSnapshot = await doc.get();
    const currentData = docSnapshot.data() || {};
    const currentMetering = currentData.metering || {};
    const currentLegs = currentMetering.legs || {};

    let updateData = {
      metering: {
        ...currentMetering,
        updateMode,
      },
    };

    if (legIndex > -1) {
      updateData.metering.legs = {
        ...currentLegs,
        [legIndex]: {
          ...currentLegs[legIndex],
          trendMonitoring: {
            ...currentLegs[legIndex]?.trendMonitoring,
            ...meteringData.trendMonitoring,
          },
          vorRvsm: {
            ...currentLegs[legIndex]?.vorRvsm,
            ...meteringData.vorRvsm,
          },
          flightTimes: {
            ...currentLegs[legIndex]?.flightTimes,
            ...meteringData.flightTimes,
          },
        },
      };
    } else {
      updateData.metering.overall = {
        trendMonitoring: meteringData.trendMonitoring ?? {},
        vorRvsm: meteringData.vorRvsm ?? {},
        flightTimes: meteringData.flightTimes ?? {},
      };
    }

    await doc.set(updateData, { merge: true });

    return true;
  } catch (error) {
    console.error('Failed to save metering data:', error);
    throw error;
  }
}

export async function saveMeteringDataMode(docPath, updateMode = 'leg') {
  try {
    const doc = app.firestore().doc(docPath);

    const updateData = {
      metering: {
        updateMode,
      },
    };

    await doc.set(updateData, { merge: true });

    return true;
  } catch (error) {
    console.error('Failed to save metering update mode:', error);
    throw error;
  }
}

/* Important:
Firebase is intentionally
rerendering the app, it seems to cause a render pain in the js thread, this optimization helps
but is not 100% optimal on the other hand it
should work better on a real device.
*/
export async function savePostFlightChecklist(updatedItems, docPath) {
  try {
    // Only update postFlightChecklist field to avoid unnecessary cloning of the whole trip
    const doc = app.firestore().doc(docPath);
    // Save only the necessary field, using merge to avoid overwriting other data
    await doc.set(
      {
        aircraft: {
          postFlightChecklist: updatedItems,
        },
      },
      { merge: true },
    );
    // console.info('PostFlightChecklist saved successfully');
  } catch (error) {
    // console.error('Failed to save postFlightChecklist:', error);
  }
}

// optimistic update
export const usePostFlightChecklist = (trip, docPath) => {
  const [checklist, setChecklist] = useState(defaultChecklistItems);
  const [menuVisibility, setMenuVisibility] = useState({});

  useEffect(() => {
    if (!trip?.aircraft?.postFlightChecklist) {
      return;
    }

    setChecklist(trip.aircraft.postFlightChecklist);
  }, [trip?.aircraft?.postFlightChecklist]);

  const handleStatusChange = useCallback(
    async (index, newStatus) => {
      try {
        const updatedItems = checklist.map((item, i) =>
          i === index ? { ...item, status: newStatus } : item,
        );

        await savePostFlightChecklist(updatedItems, docPath);

        setChecklist(updatedItems);
        toggleMenuVisibility(index);
      } catch (error) {
        console.error('Failed to save postFlightChecklist:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checklist, docPath],
  );

  const toggleMenuVisibility = useCallback(index => {
    setMenuVisibility(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  }, []);

  return {
    checklist,
    menuVisibility,
    handleStatusChange,
    toggleMenuVisibility,
  };
};

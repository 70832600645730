import React, { useState } from 'react';
import { View, StyleSheet, ScrollView, Text } from 'react-native';
import { useTheme } from '@appComponents/theme';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { TrendMonitoringModal } from './Modals/TrendMonitoringModal';
import { StyledIconButton } from '@appComponents/Metering/StyledIconButton';

export const TrendMonitoring = ({
  trip,
  aircraft,
  handleSelectedLegChange,
}) => {
  const theme = useTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { watch, selectedLeg, setSelectedLeg } = useMeteringContext();
  const engineFields = [
    { label: 'N1 (%)', field: 'N1' },
    { label: 'N2/Prop (%)', field: 'N2' },
    { label: 'Torque (lbs)', field: 'Torque' },
    { label: 'ITT (°C)', field: 'ITT' },
    { label: 'Fuel Flow (lbs)', field: 'FuelFlow' },
    { label: 'Oil Pressure (psi)', field: 'OilPressure' },
    { label: 'Oil Temp (°C)', field: 'OilTemp' },
  ];
  const updateMode = watch('updateMode');

  const openModal = leg => {
    setSelectedLeg(leg);
    if (leg?.index > -1) {
      handleSelectedLegChange(leg);
    }
    setIsModalVisible(true);
  };

  const closeModal = () => {
    const newLeg =
      selectedLeg?.index === -1
        ? { index: -1, value: -1, id: -1 }
        : { index: 0, value: 0, id: trip?.legs?.[0]?.id ?? 0 };
    setSelectedLeg(newLeg);
    if (newLeg.index > -1) {
      handleSelectedLegChange(newLeg);
    }
    setIsModalVisible(false);
  };

  const getTrendInfoMonitoringData = (field, index) => {
    if (updateMode === 'leg' && index > -1) {
      return trip?.metering?.legs?.[index]?.trendMonitoring?.[field] || '-';
    }

    return trip?.metering?.overall?.trendMonitoring?.[field] || '-';
  };

  const getTrendMonitoringEngineData = (field, engineNumber, index) => {
    if (updateMode === 'leg' && index > -1) {
      return (
        trip?.metering?.legs?.[index]?.trendMonitoring?.[
          `engine${engineNumber}${field}`
        ] || '-'
      );
    }

    return (
      trip?.metering?.overall?.trendMonitoring?.[
        `engine${engineNumber}${field}`
      ] || '-'
    );
  };

  const renderMainInfo = (leg, index) => {
    return (
      <View style={styles.infoContainer}>
        {updateMode === 'leg' ? (
          <Text style={[styles.legHeader, { color: theme.colors.text }]}>
            {leg?.from} &gt; {leg?.to}
          </Text>
        ) : null}
        <View style={styles.infoRow}>
          <View style={styles.infoColumn}>
            <Text
              style={[
                styles.infoLabel,
                styles.ratLabel,
                { color: theme.colors.text },
              ]}>
              RAT
            </Text>
            <View
              style={[
                styles.infoValue,
                { backgroundColor: theme.colors.background },
              ]}>
              <Text
                style={[
                  styles.infoText,
                  styles.ratText,
                  { color: theme.colors.text },
                ]}>
                {getTrendInfoMonitoringData('rat', index)}
              </Text>
            </View>
          </View>
          <View style={styles.infoColumn}>
            <Text style={[styles.infoLabel, { color: theme.colors.text }]}>
              ALTITUDE AT RAT
            </Text>
            <View
              style={[
                styles.infoValue,
                { backgroundColor: theme.colors.background },
              ]}>
              <Text style={{ color: theme.colors.text }}>
                {getTrendInfoMonitoringData('altitudeAtRat', index)}
              </Text>
            </View>
          </View>
          <View style={styles.infoColumnSmall}>
            <Text style={[styles.infoLabel, { color: theme.colors.text }]}>
              MACH
            </Text>
            <View
              style={[
                styles.infoValue,
                { backgroundColor: theme.colors.background },
              ]}>
              <Text style={{ color: theme.colors.text }}>
                {getTrendInfoMonitoringData('mach', index)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderEngineFields = (engineNumber, index) => (
    <View key={`engine-${engineNumber}`} style={styles.engineColumn}>
      <Text style={[styles.engineHeader, { color: theme.colors.text }]}>
        ENGINE {engineNumber}
      </Text>
      {engineFields.map(({ label, field }) => (
        <View
          key={field}
          style={[
            styles.engineRow,
            { backgroundColor: theme.colors.background },
          ]}>
          <Text style={{ color: theme.colors.text }}>
            {getTrendMonitoringEngineData(field, engineNumber, index)}
          </Text>
        </View>
      ))}
    </View>
  );

  return (
    <>
      <ScrollView style={styles.container}>
        {updateMode === 'leg' ? (
          Object.entries(trip.legs).map(([_, leg], index) => (
            <View key={leg.id}>
              <View
                style={[
                  styles.legHeaderContainer,
                  { borderBottomColor: theme.colors.white },
                ]}>
                <Text
                  style={[styles.legIndexText, { color: theme.colors.text }]}>
                  Leg {index + 1}
                </Text>
                <StyledIconButton
                  icon="edit"
                  backgroundColor={theme.colors.primary}
                  onPress={() => {
                    openModal({ ...leg, index });
                  }}
                />
              </View>
              {renderMainInfo(leg, index)}
              <View style={styles.engineContainer}>
                <View style={styles.engineWrapper}>
                  <View style={styles.engineContent}>
                    <View style={styles.engineLabelColumn}>
                      <Text
                        style={[
                          styles.engineHeader,
                          styles.placeholderLabel,
                          { color: theme.colors.tableBackground },
                        ]}>
                        placeholder
                      </Text>
                      {engineFields.map(({ label }) => (
                        <View
                          key={label}
                          style={[
                            styles.engineRow,
                            { backgroundColor: theme.colors.background },
                          ]}>
                          <Text style={styles.engineLabel}>{label}</Text>
                        </View>
                      ))}
                    </View>
                    {[...Array(aircraft.numEngines)].map((_, i) =>
                      renderEngineFields(i + 1, index),
                    )}
                  </View>
                </View>
              </View>
            </View>
          ))
        ) : (
          <View>
            <View
              style={[
                styles.legHeaderContainer,
                { borderBottomColor: theme.colors.white },
              ]}>
              <StyledIconButton
                icon="edit"
                backgroundColor={theme.colors.primary}
                onPress={() => openModal(null)}
                hasMargin
              />
            </View>
            {renderMainInfo(null)}
            <View style={styles.engineContainer}>
              <View style={styles.engineWrapper}>
                <View style={styles.engineContent}>
                  <View style={styles.engineLabelColumn}>
                    <Text
                      style={[
                        styles.engineHeader,
                        styles.placeholderLabel,
                        { color: theme.colors.tableBackground },
                      ]}>
                      placeholder
                    </Text>
                    {engineFields.map(({ label }) => (
                      <View
                        key={label}
                        style={[
                          styles.engineRow,
                          { backgroundColor: theme.colors.background },
                        ]}>
                        <Text style={styles.engineLabel}>{label}</Text>
                      </View>
                    ))}
                  </View>
                  {[...Array(aircraft.numEngines)].map((_, i) =>
                    renderEngineFields(i + 1, null),
                  )}
                </View>
              </View>
            </View>
          </View>
        )}
      </ScrollView>
      {isModalVisible ? (
        <TrendMonitoringModal
          aircraft={aircraft}
          onClose={closeModal}
          updateMode={updateMode}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  legHeaderContainer: {
    borderBottomWidth: 1,
    padding: 12,
    marginTop: 22,
    flexDirection: 'row',
    alignItems: 'center',
  },
  legIndexText: {
    fontSize: 18,
  },
  infoContainer: {
    marginVertical: 32,
  },
  legHeader: {
    flexShrink: 1,
    marginBottom: 32,
    marginHorizontal: 12,
    fontWeight: 'semibold',
    fontSize: 18,
  },
  infoRow: {
    width: '60%',
    flexDirection: 'row',
  },
  infoColumn: {
    flex: 0.5,
  },
  infoColumnSmall: {
    flex: 0.2,
  },
  infoLabel: {
    fontWeight: 'bold',
  },
  ratLabel: {
    marginLeft: 12,
  },
  infoValue: {
    marginVertical: 8,
    paddingVertical: 14,
  },
  infoText: {},
  ratText: {
    marginLeft: 12,
  },
  engineContainer: {
    flex: 1,
    marginTop: 32,
  },
  engineWrapper: {},
  engineContent: {
    flexDirection: 'row',
  },
  engineLabelColumn: {
    width: '25%',
  },
  engineColumn: {
    flex: 0.5,
  },
  engineHeader: {
    fontWeight: 'bold',
    margin: 8,
  },
  placeholderLabel: {
    marginLeft: 12,
  },
  engineRow: {
    flexDirection: 'row',
    marginVertical: 2,
    padding: 14,
  },
  engineLabel: {
    color: 'white',
  },
});

// Returns the total amount due including fees
export const calculatePaymentFees = (amount, feeData, type) => {
  const { percentage, fixed, capped } = feeData;

  if (type === 'card') {
    return (amount + fixed / 100) / (1 - percentage / 100);
  }

  if (type === 'us_bank_account') {
    const variableAmount = amount / (1 - percentage / 100);
    const cappedAmount = capped / 100;
    if (variableAmount - amount > cappedAmount) {
      return amount + cappedAmount;
    }
    return variableAmount;
  }
};
